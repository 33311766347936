import styled from "styled-components"
import { Empty, Button, Divider, Checkbox, Tooltip, Input, Typography } from "antd"
import { CheckCircleFilled, PlusCircleFilled, QuestionCircleOutlined } from "@ant-design/icons"

import { Centered, Colors, Spaces, StyleHelpers } from "../../global"
import type { AccessItem, User } from "../../../coreTypes/config"
import AccessPermissionForm, { AccessPermissionFormProps } from "./AccessPermissionForm"
import AccessPermissionItem from "./AccessPermissionItem"
import { Switcher, SwitchersGroup } from "../../Switcher"

const { Title } = Typography

export default function AccessPermissionsContainer(props: {
    entityName: string
    users: User[]
    accessItems: AccessItem[]
    isSavingUserForm: boolean
    isPermissionForm: boolean
    isFieldForm: boolean
    sendEditAccessPermissionItem: (accessItem?: AccessItem, accessItemIndex?: number) => void
    isInvitable: boolean
    setInvitable: (groupName: string) => void
    switchInvitable: () => void
    switchSignupable: () => void
    setEmailInvitationUrl: (url: string) => void
    setUserInvitationUrl: (url: string) => void
    accessPermisionFormProps: AccessPermissionFormProps
}) {
    const {
        entityName,
        users,
        accessItems,
        isSavingUserForm,
        isPermissionForm,
        isFieldForm,
        sendEditAccessPermissionItem,
        isInvitable,
        setInvitable,
        switchInvitable,
        switchSignupable,
        setEmailInvitationUrl,
        setUserInvitationUrl,
        accessPermisionFormProps,
    } = props

    const { userForm } = accessPermisionFormProps
    const user: User | undefined = userForm?.user

    return (
        <PermissionsWrapper>
            <AccessPermissionsWrapper>
                <Title level={2} style={{ marginBottom: isPermissionForm ? 0 : "25px" }}>
                    Access permissions
                </Title>
                {isPermissionForm ? (
                    <AccessPermissionForm {...accessPermisionFormProps} />
                ) : (
                    <AccessItemsWrapper>
                        {accessItems.length === 0 ? (
                            <Centered>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No access permissions for this entity created yet" />
                            </Centered>
                        ) : (
                            accessItems.map((accessItem: AccessItem, index: number) => {
                                return (
                                    <AccessPermissionItem
                                        entityName={user?.groupName ?? entityName}
                                        isFieldForm={isFieldForm}
                                        sendEditAccessPermissionItem={() => {
                                            sendEditAccessPermissionItem(accessItem, index)
                                        }}
                                        accessItem={accessItem}
                                        key={accessItem.type + index}
                                        index={index}
                                    />
                                )
                            })
                        )}
                        <Centered>
                            <Button
                                disabled={isFieldForm}
                                icon={<PlusCircleFilled />}
                                type="primary"
                                onClick={() => {
                                    sendEditAccessPermissionItem()
                                }}
                            >
                                Add permission
                            </Button>
                        </Centered>
                    </AccessItemsWrapper>
                )}
            </AccessPermissionsWrapper>

            {!isPermissionForm && user && (
                <InvitationPermissionsWrapper>
                    <Divider />
                    <h2>Invitation permissions</h2>

                    <SwitchersGroup style={{ flexDirection: "row", alignItems: "start", marginBottom: Spaces.medium }}>
                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={
                                user.invitable
                                    ? "(enabled) Allows selected users to send invitations to sign-up to this user group (rule-based in the right side panel)."
                                    : "Enable to allow selected users to send invitations to sign-up to this user group (rule-based in the right side panel)."
                            }
                            placement="bottomLeft"
                        >
                            <Switcher
                                selected={user.invitable}
                                inactive={isFieldForm || isPermissionForm || isSavingUserForm}
                                onClick={() => !isFieldForm && !isPermissionForm && switchInvitable()}
                                style={{ lineHeight: 1.5 }}
                            >
                                {user.invitable && (
                                    <CheckCircleFilled
                                        style={{
                                            fontSize: StyleHelpers.iconSize,
                                            color: isFieldForm || isPermissionForm ? Colors.grayNormal : Colors.background,
                                        }}
                                    />
                                )}
                                {user.invitable ? " is invitable" : "Make invitable"}
                            </Switcher>
                        </Tooltip>
                        <Tooltip
                            title={
                                userForm?.originalUser === undefined
                                    ? "Save current user group first to be able to activate this option."
                                    : user.signupable
                                      ? "(enabled) Allowing users to sign-up to this user group directly without invitation (publicly available sign-up)."
                                      : "Enable to allow users to sign-up to this user group directly without invitation (publicly available sign-up)."
                            }
                            placement="bottomRight"
                        >
                            <Switcher
                                selected={user.signupable}
                                inactive={isFieldForm || isPermissionForm || userForm?.originalUser === undefined || isSavingUserForm}
                                onClick={() => !isFieldForm && !isPermissionForm && userForm?.originalUser !== undefined && switchSignupable()}
                                style={{ lineHeight: 1.5 }}
                            >
                                {user.signupable && (
                                    <CheckCircleFilled
                                        style={{
                                            fontSize: StyleHelpers.iconSize,
                                            color:
                                                isFieldForm || isPermissionForm || userForm?.originalUser === undefined
                                                    ? Colors.grayNormal
                                                    : Colors.background,
                                        }}
                                    />
                                )}
                                {user.signupable ? "direct sign-up allowed" : "Allow direct sign-up"}
                            </Switcher>
                        </Tooltip>
                    </SwitchersGroup>

                    {user.invitable && (
                        <>
                            <Title level={4}>"{user.groupName}" can be invited by following users</Title>
                            {users.map((u) => (
                                <CheckboxesWrapper>
                                    <Checkbox
                                        checked={accessPermisionFormProps.userForm?.user.invitableBy?.includes(u.groupName)}
                                        onClick={() => {
                                            setInvitable(u.groupName)
                                        }}
                                    >
                                        {u.groupName}
                                    </Checkbox>
                                </CheckboxesWrapper>
                            ))}
                        </>
                    )}

                    <Title level={4}>Email URLs for web app to handle invitations</Title>
                    <UrlsWrapper>
                        <div>
                            <Input
                                placeholder="Email invitation destination URL"
                                title="https://example.com/profile-sign-up/?email=#email#&code=#invitationCode#"
                                style={{ width: "300px" }}
                                value={accessPermisionFormProps.userForm?.user.acceptEmailInvitationDestinationUrl}
                                onChange={(e) => {
                                    setEmailInvitationUrl(e.target.value)
                                }}
                            />
                            <Tooltip
                                overlayInnerStyle={{ width: "400px" }}
                                title={
                                    `"Email invitation" flow (is used to invite just by email) – means there is NO user profile yet. Person follows URL from email message and has to submit profile details through GQL create mutation. ` +
                                    `URL should contain mandatory #email# and #invitationCode# placeholders to hadnle further API call within your app. ` +
                                    `Reference URL: "https://example.com/profile-sign-up/?email=#email#&code=#invitationCode#"`
                                }
                            >
                                <QuestionCircleOutlined style={{ marginLeft: Spaces.normal }} />
                            </Tooltip>
                        </div>
                        <div>
                            <Input
                                placeholder="User invitation destination URL"
                                title="https://example.com/set-password/?email=#email#&code=#invitationCode#"
                                style={{ width: "300px" }}
                                value={accessPermisionFormProps.userForm?.user.acceptUserInvitationDestinationUrl}
                                onChange={(e) => {
                                    setUserInvitationUrl(e.target.value)
                                }}
                            />
                            <Tooltip
                                overlayInnerStyle={{ width: "400px" }}
                                title={
                                    `"User invitation" flow (is used to create new user account and then just to invite him to set new password) – means there is existing user profile and person will follow URL from email message and has to set new password only. ` +
                                    `URL should contain mandatory #email# and #invitationCode# placeholders to hadnle further API call within your app. ` +
                                    `Reference URL: "https://example.com/set-password/?email=#email#&code=#invitationCode#"`
                                }
                            >
                                <QuestionCircleOutlined style={{ marginLeft: Spaces.normal }} />
                            </Tooltip>
                        </div>
                    </UrlsWrapper>
                </InvitationPermissionsWrapper>
            )}
        </PermissionsWrapper>
    )
}

const AccessPermissionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 50vh;
`
const AccessItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.large};
`

const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const UrlsWrapper = styled.div`
    padding: ${Spaces.large};
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: ${Spaces.normal};
`

const InvitationPermissionsWrapper = styled.div`
    margin-bottom: 90px;
`

const PermissionsWrapper = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
    padding: 5px;
`
