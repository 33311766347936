import { FC } from "react"
import { Spaces, StyleHelpers } from "../../global"
import { Button, Empty, Typography } from "antd"
import { DeleteOutlined, KeyOutlined, LoginOutlined, LogoutOutlined, PlusCircleFilled, SwapOutlined, UserOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { TextTag } from "../ApiSimulatorTab/AuthComponents"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"

const { Text } = Typography

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
}

export const ActiveSessionsList: FC<Props> = ({ current, send }) => (
    <ActiveSessionsListWrapper>
        <Text type="secondary" style={{ marginTop: Spaces.normal }}>
            Active user sessions:
        </Text>

        {current.context.apiAuth.authSessionsList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No active sessions yet..." />
        ) : (
            current.context.apiAuth.authSessionsList.map((us, idx: number) => (
                <UserSession key={idx}>
                    <SessionInfo>
                        {us.authType == "USERGROUP" ? (
                            <UserOutlined style={{ fontSize: StyleHelpers.iconSize }} />
                        ) : (
                            <KeyOutlined style={{ fontSize: StyleHelpers.iconSize }} />
                        )}
                        <Text underline>{us.username}</Text>
                        {us.authType == "USERGROUP" && <TextTag>{us.userGroup}</TextTag>}
                    </SessionInfo>
                    {us.username === current.context.apiAuth.currentAuthSession?.username && current.matches("default.authTokenWorker.idle") ? (
                        <Button
                            type="primary"
                            size="small"
                            icon={<LogoutOutlined />}
                            onClick={() => {
                                send("SESSION_LOGOUT")
                            }}
                            disabled={[
                                "default.tabs.apiUsers.emailInvitation.splitScreen.signup.profile",
                                "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmForm",
                                "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmingInvitation",
                            ].some(current.matches)}
                        >
                            Log out
                        </Button>
                    ) : (
                        <ButtonsGroup>
                            <Button
                                size="small"
                                icon={current.context.apiAuth.currentAuthSession ? <SwapOutlined /> : <LoginOutlined />}
                                loading={
                                    current.matches("default.authTokenWorker.refreshingToken") &&
                                    us.username === current.context.apiAuth.currentAuthSession?.username
                                }
                                onClick={() => {
                                    send({ type: "SELECT_SESSION", sessionIndex: idx })
                                }}
                                disabled={[
                                    "default.tabs.apiUsers.emailInvitation.splitScreen.signup.profile",
                                    "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmForm",
                                    "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmingInvitation",
                                    "default.authTokenWorker.refreshingToken",
                                ].some(current.matches)}
                            >
                                {current.context.apiAuth.currentAuthSession ? "Switch" : "Log In"}
                            </Button>
                            {!current.context.apiAuth.currentAuthSession && (
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    disabled={[
                                        "default.tabs.apiUsers.emailInvitation.splitScreen.signup.profile",
                                        "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmForm",
                                        "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmingInvitation",
                                        "default.authTokenWorker.refreshingToken",
                                    ].some(current.matches)}
                                    onClick={() => {
                                        send({ type: "SESSION_DELETE", sessionIndex: idx })
                                    }}
                                />
                            )}
                        </ButtonsGroup>
                    )}
                </UserSession>
            ))
        )}

        <Button
            icon={<PlusCircleFilled />}
            style={{ margin: `${Spaces.normal} auto` }}
            type="primary"
            onClick={() => {
                send("GO_LOGIN")
            }}
            disabled={[
                "default.tabs.apiUsers.emailInvitation.splitScreen.signup.profile",
                "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmForm",
                "default.tabs.apiUsers.emailInvitation.splitScreen.signup.confirmation.confirmingInvitation",
                "default.authTokenWorker.refreshingToken",
            ].some(current.matches)}
        >
            Log in
        </Button>

        {current.matches("default.tabs.apiUsers.loginSession.sessions") && (
            <div style={{ textAlign: "end", marginTop: Spaces.medium }}>
                <Button
                    size="large"
                    type="primary"
                    loading={["default.tabs.apiUsers.verifyingEmail", "default.tabs.apiUsers.logingIn"].some(current.matches)}
                    onClick={() => {
                        send("GO_SIMULATOR")
                    }}
                >
                    Go to Live API
                </Button>
            </div>
        )}
    </ActiveSessionsListWrapper>
)

const ActiveSessionsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const SessionInfo = styled.div`
    & > * {
        margin-right: ${Spaces.medium};
    }
    display: flex;
`

export const UserSession = styled.div`
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
`
const ButtonsGroup = styled.div`
    display: flex;
    gap: ${Spaces.small};
    align-items: center;
`
