import _ from "lodash"
import type { AccessField, Config, ConfigEntity, EntityField, Operation, User } from "../coreTypes/config"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

/**
 * convert the utc time to local time for end-user and return the local time value based on time format specified
 *
 * @param utcTime utc time
 * @param format time format for example 'YYYY-MM-DD HH:mm:ss'
 * @returns local time
 */
function getLocalTime(utcTime: string, format?: string) {
    // Convert UTC to local time
    const localTime = dayjs.utc(utcTime).local() // Convert UTC to local timezone

    // Format the local time as desired
    const formattedLocalTime = localTime.format(format || "YYYY-MM-DD HH:mm:ss") // Customize the format as needed

    return formattedLocalTime
}

/**
 * check the given value is UUID or not
 *
 * @param value string value
 * @returns if string is UUID, true or false
 */
function isUUID(value: string) {
    const uuidRegex = /^[0-9a-f]{24}$/i // Adjust the regex according to your UUID format
    return uuidRegex.test(value)
}

// function getPlural(noun: string): string {
//     if (noun.endsWith("y")) {
//         return noun.slice(0, -1) + "ies"
//     } else if (noun.endsWith("s") || noun.endsWith("sh") || noun.endsWith("ch")) {
//         return noun + "es"
//     } else {
//         return noun + "s"
//     }
// }
// function getSingular(plural: string): string {
//     if (plural.endsWith("ies")) {
//         return plural.slice(0, -3) + "y"
//     } else if (plural.endsWith("es")) {
//         return plural.slice(0, -2)
//     } else if (plural.endsWith("s")) {
//         return plural.slice(0, -1)
//     } else {
//         return plural
//     }
// }

/**
 * Looks for entity in config's entities or users and return all its fields
 *
 * @param config project config
 * @param entityName name of the entity to look within
 *
 * @returns array of entity's fields
 */
function GetEntityAllFields(config: Config, entityName: string, withDefatults = true, idConversion = false): EntityField[] | undefined {
    let entity: ConfigEntity | User | { fields: EntityField[] } | undefined

    entity = config.users.find((e) => e.groupName == entityName)
    if (entity) {
        const fields = withDefatults
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              MergeFieldsWithDefaultFields(entity.userData, idConversion)
            : (
                  [
                      {
                          name: "email",
                          description: "Email address is used for sign-up",
                          type: "EMAIL",
                          mandatory: true,
                          mutable: false,
                      },
                  ] as EntityField[]
              ).concat(entity.userData.fields ?? [])
        return fields
    }

    entity = config.entities.find((e) => e.entityName == entityName) as ConfigEntity
    if (entity) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const fields = withDefatults ? MergeFieldsWithDefaultFields(entity, idConversion) : entity?.fields
        return fields
    }

    if (!entity) entity = config.subObjects?.[entityName] as { fields: EntityField[] }
    if (entity) {
        return entity?.fields
    }

    return
}

function MergeFieldsWithDefaultFields(data: ConfigEntity | User["userData"], idConversion: boolean) {
    if (!data) return []

    const defaultFields = data.defaultFields
        ? Object.keys(data.defaultFields).map((name) => {
              const field = {
                  // use _id instead of id across FE and BE is also supported to avoid any potentials bugs
                  name: name == "_id" && idConversion ? "id" : name,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  type: data.defaultFields[name],
              } as EntityField
              if (name == "email") {
                  field["mandatory"] = true
                  field["description"] = "User email for sign-up"
              }
              return field
          })
        : []

    return data.fields ? defaultFields.concat(data.fields) : defaultFields
}

/**
 * Looks for entity in config's entities or users and return it
 *
 * @param config project config
 * @param entityName name of the entity to look within
 *
 * @returns entity
 */
function GetEntity(config: Config, entityName: string): ConfigEntity | User | undefined {
    let entity: ConfigEntity | User | undefined

    entity = config.users.find((e) => e.groupName == entityName)
    if (entity) {
        return entity
    }

    entity = config.entities.find((e) => e.entityName == entityName)
    if (entity) {
        return entity
    }
}

/**
 * Get mandatory sub fields name array of the given parent field
 *
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 * @param parentFieldName the given parent field name
 *
 * @returns mandatory fields name array
 */
function getSubMandatoryFieldNames(projectConfig: Config, entitiyFields: EntityField[], parentFieldName: string) {
    const getSubObjectName = entitiyFields.find((f) => f.name === parentFieldName)?.subObjectName || ""

    let getSubObjects: string[] = []
    if (entitiyFields && getSubObjectName !== "" && projectConfig?.subObjects) {
        getSubObjects = projectConfig?.subObjects[getSubObjectName].fields.map((sf) => {
            if (sf.mandatory) {
                return sf.name
            } else if (["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(sf.type) && sf?.connectionUnderlyingField) {
                if (sf.connectionUnderlyingField.mandatory) {
                    return sf.connectionUnderlyingField.name
                }
            }
            return ""
        })
    }
    return getSubObjects.filter((subObj) => subObj !== "")
}

/**
 * Get all sub-fields name array of the given parent field
 *
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 * @param parentFieldName the given parent field name
 *
 * @returns mandatory fields name array
 */
function getAllSubFieldNames(projectConfig: Config, entitiyFields: EntityField[], parentFieldName: string) {
    const getSubObjectName = entitiyFields.find((f) => f.name === parentFieldName)?.subObjectName || ""

    let getSubObjects: string[] = []
    if (entitiyFields && getSubObjectName !== "" && projectConfig?.subObjects) {
        getSubObjects = projectConfig?.subObjects[getSubObjectName].fields.map((sf) => {
            if (["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(sf.type) && sf?.connectionUnderlyingField) {
                return sf.connectionUnderlyingField.name
            } else {
                return sf.name
            }
        })
    }
    return getSubObjects
}

/**
 * Get all sub fields name array of the given parent field
 *
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 * @param parentFieldName the given parent field name
 *
 * @returns all sub fields name array
 */
function getSubFieldNames(projectConfig: Config, entitiyFields: EntityField[], parentFieldName: string) {
    const getSubObjectName = entitiyFields.find((f) => f.name === parentFieldName)?.subObjectName || ""

    let getSubObjects: string[] = []
    if (entitiyFields && getSubObjectName !== "" && projectConfig?.subObjects) {
        getSubObjects = projectConfig?.subObjects[getSubObjectName].fields.map((sf) => {
            if (["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(sf.type) && sf?.connectionUnderlyingField) {
                return sf.connectionUnderlyingField.name
            } else {
                return sf.name
            }
        })
    }
    return getSubObjects.filter((subObj) => subObj !== "")
}

/**
 * Get mandatory fields array of the given parent field
 *
 * @param contextFields access fields
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 * @param parentField the given parent field
 *
 * @returns mandatory fields
 */
function getMandatoryAccessFieldsOfParentField(
    contextFields: AccessField[],
    projectConfig: Config,
    entitiyFields: EntityField[],
    parentField: AccessField
) {
    const getMandatorySubObjNames = getSubMandatoryFieldNames(projectConfig, entitiyFields, parentField.fieldName)
    const result: AccessField[] = []
    if (getMandatorySubObjNames.length > 0 && contextFields.length > 0) {
        getMandatorySubObjNames.forEach((sn) => {
            result.push(contextFields.find((cf) => cf.fieldName === parentField.fieldName && cf.subfield?.fieldName === sn)!)
        })
    }
    return result.filter((r) => r !== undefined)
}

/**
 * Get all fields array of the given parent field
 *
 * @param contextFields access fields
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 * @param parentField the given parent field
 *
 * @returns mandatory fields
 */
function getAllAccessFieldsOfParentField(
    contextFields: AccessField[],
    projectConfig: Config,
    entitiyFields: EntityField[],
    parentField: AccessField
) {
    const getAllSubObjNames = getAllSubFieldNames(projectConfig, entitiyFields, parentField.fieldName)
    const result: AccessField[] = []
    if (getAllSubObjNames.length > 0 && contextFields.length > 0) {
        getAllSubObjNames.forEach((sn) => {
            result.push(contextFields.find((cf) => cf.fieldName === parentField.fieldName && cf.subfield?.fieldName === sn)!)
        })
    }
    return result.filter((r) => r !== undefined)
}

/**
 * Whether the given access field is mandatory or not
 *
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 * @param targetField the given access field
 *
 * @returns true is mandatory, false is non-mandatory
 */
function isMandatoryField(projectConfig: Config, entitiyFields: EntityField[], targetField: AccessField) {
    let result = false
    if (targetField.subfield) {
        if (getSubMandatoryFieldNames(projectConfig, entitiyFields, targetField.fieldName).includes(targetField.subfield.fieldName)) {
            result = true
        }
    } else {
        entitiyFields.forEach((ef) => {
            if (["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(ef.type)) {
                if (ef.connectionUnderlyingField?.name === targetField.fieldName && ef.connectionUnderlyingField.mandatory) {
                    result = true
                }
            }
        })
        const targetEntity = entitiyFields.find((f) => f.name === targetField.fieldName)
        if (targetEntity?.mandatory) {
            result = true
        }
    }
    return result
}

/**
 * Set operation for all access fields
 *
 * @param accessFields access fields array
 * @param operation operation to be set
 *
 * @returns updated access fields array
 */
function setOperationAllFields(accessFields: AccessField[], operation: Operation) {
    accessFields.forEach((field) => {
        if (!field.operations?.includes(operation)) {
            field.operations?.push(operation)
        }
    })
    return accessFields
}

/**
 * Remove operation for all access fields
 *
 * @param accessFields access fields array
 * @param operation operation to be removed
 *
 * @returns updated access fields array
 */
function removeOperationAllFields(accessFields: AccessField[], operation: Operation) {
    accessFields.forEach((field) => {
        field.operations = field.operations?.filter((op) => op !== operation)
    })
    return accessFields
}

/**
 * Remove operation for all access fields
 *
 * @param accessFields access fields array
 * @param operation given operation
 *
 * @returns fields with the specific operation
 */
function fieldsWithOperation(accessFields: AccessField[], operation: Operation) {
    return accessFields.filter((af) => af.operations?.includes(operation))
}

/**
 * Get all access fields
 *
 * @param projectConfig project config
 * @param entitiyFields entity fields array
 *
 * @returns all access fields including no operations
 */
function getAllAccessFields(projectConfig: Config, entitiyFields: EntityField[]) {
    const topLevelFields: AccessField[] = []
    const subobjectFields: AccessField[] = []
    entitiyFields.forEach((entity) => {
        if (["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(entity.type)) {
            if (entity.connectionUnderlyingField?.name)
                topLevelFields.push({
                    fieldName: entity.connectionUnderlyingField.name,
                    operations: [],
                })
        } else {
            if (["SUBOBJECT"].includes(entity.type)) {
                const subObjectNames = getSubFieldNames(projectConfig, entitiyFields, entity.name)
                subObjectNames.forEach((sn) => {
                    subobjectFields.push({
                        fieldName: entity.name,
                        operations: [],
                        subfield: {
                            fieldName: sn,
                        },
                    })
                })
            } else {
                topLevelFields.push({
                    fieldName: entity.name,
                    operations: [],
                })
            }
        }
    })

    return [...topLevelFields, ...subobjectFields]
}

/**
 * Get the entity field for the selecting access field
 *
 * @param allEntityFields all entity fields
 * @param selectedAccessField selecting access field
 *
 * @returns return entity field of selecting access field
 */
function getEntityField(allEntityFields: EntityField[], selectedAccessField: AccessField) {
    return allEntityFields.find(
        (f) => f.name === selectedAccessField.fieldName || f.connectionUnderlyingField?.name === selectedAccessField.fieldName
    )!
}

/**
 * Function to flatten the structure recursively for comparison
 *
 * @param field given field
 *
 * @returns flatten field
 */
const flattenField = (field: AccessField) => {
    const flattened: AccessField = {
        fieldName: field.fieldName,
        subfield: field.subfield ? flattenField(field.subfield) : undefined,
    }
    return flattened
}

/**
 * Function to determine if two fields match, disregarding operations
 *
 * @param fieldA given first field
 * @param fieldB given second field
 *
 * @returns if matches, true, otherwise false
 */
const isFieldsMatch = (fieldA: AccessField, fieldB: AccessField) => {
    return _.isEqual(flattenField(fieldA), flattenField(fieldB))
}

/**
 * Function to convert first letter to uppercase, remove spaces, special characters, numbers and keep only letters
 *
 * @param name given string from input
 *
 * @returns clean string
 */
function cleanInputNameStartingUpper(name: string) {
    const groupNameArr = name.split(" ")
    for (const en in groupNameArr) {
        // remove spaces, special characters, numbers and keep only letters
        groupNameArr[en] = groupNameArr[en].replace(/[^a-zA-Z]/g, "")
        // set each word first letter to uppercase
        groupNameArr[en] = groupNameArr[en].charAt(0).toUpperCase() + groupNameArr[en].slice(1)
    }
    return groupNameArr.join("")
}

/**
 * Function to convert first letter to lowercase, remove spaces, special characters, numbers and keep only letters
 *
 * @param name given string from input
 *
 * @returns clean string
 */
function cleanInputNameStartingLower(name: string | undefined) {
    if (!name) return ""
    const result = cleanInputNameStartingUpper(name)
    return result[0].toLowerCase() + result.slice(1)
}

export {
    getLocalTime,
    isUUID,
    GetEntityAllFields,
    GetEntity,
    getSubMandatoryFieldNames,
    setOperationAllFields,
    isMandatoryField,
    removeOperationAllFields,
    getMandatoryAccessFieldsOfParentField,
    fieldsWithOperation,
    getAllAccessFields,
    getSubFieldNames,
    isFieldsMatch,
    getEntityField,
    getAllSubFieldNames,
    getAllAccessFieldsOfParentField,
    cleanInputNameStartingUpper,
    cleanInputNameStartingLower,
}
