import styled from "styled-components"
import { Input, Button, Select, DatePicker } from "antd"
import dayjs from "dayjs"
import { CheckCircleFilled, DeleteFilled, DeleteOutlined, PlusCircleFilled, WarningFilled } from "@ant-design/icons"

import { Config, EntityField } from "../../../coreTypes/config"
import { EndpointFilter } from "../../../machines/SimulatorModel"
import { Spaces } from "../../global"

const { OptGroup, Option } = Select

export interface FilterRulesProps {
    filters: EndpointFilter[]
    addFilterRule: () => void
    removeFilterRule: (ruleIndex: number) => void
    changeFilterField: (ruleIndex: number, field: string) => void
    changeFilterOperator: (ruleIndex: number, operator: string) => void
    changeFilterValue: (ruleIndex: number, value: string) => void
}

interface FilterRulesPropsExtended extends FilterRulesProps {
    projectConfig: Config
    filterFields: EntityField[]
}

const renderValueInput = (
    projectConfig: Config,
    filter: EndpointFilter,
    ruleIndex: number,
    changeFilterValue: (ruleIndex: number, value: string) => void,
    field?: EntityField
) => {
    if (field?.isArray || filter.operator === "IN") {
        // TODO: edge case is low probable 1 array of boolean and 2 array of date need to be handled
        const dropDownProps: any = {}
        if (field?.type !== "ENUM") {
            dropDownProps["open"] = false
            dropDownProps["dropdownRender"] = () => <div></div>
            dropDownProps["dropdownStyle"] = { display: "none" }
        }
        return (
            <Select
                // show dropdown only for ENUM
                {...dropDownProps}
                //
                style={{ width: "100%" }}
                mode="tags"
                tokenSeparators={[","]}
                placeholder="Values (insert comma separated values)"
                title="Values (insert comma separated values)"
                showSearch={false}
                value={filter.value}
                suffixIcon={<></>}
                onChange={(e) => {
                    changeFilterValue(ruleIndex, e)
                }}
            >
                {field?.type === "ENUM" &&
                    projectConfig.enums?.[field.enumName!].map((t) => {
                        return (
                            <Option value={t} key={t}>
                                {t}
                            </Option>
                        )
                    })}
            </Select>
        )
    } else {
        switch (field?.type) {
            case "CONNECTION_BELONGSTO":
            case "CONNECTION_CONTAINS":
            case "USER_ID":
            case "OBJECT_ID":
            case "USERSELF":
            // TODO: add validation of ID when submit + handle array of values [Tasks]
            case "EMAIL":
            // TODO: add validation of email
            case "PHONENUMBER":
            // TODO: add validation of phone number
            case "STRING":
                return (
                    <Input
                        placeholder={`Enter ${
                            ["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS", "USER_ID", "OBJECT_ID", "USERSELF"].includes(field.type)
                                ? "Object ID"
                                : field.type
                        } value...`}
                        style={{ width: "50%" }}
                        value={filter.value}
                        onChange={(e) => {
                            changeFilterValue(ruleIndex, e.target.value)
                        }}
                    />
                )
            case "INT":
            case "FLOAT":
            case "DOUBLE":
                return (
                    <Input
                        placeholder={`Enter ${field.type} value...`}
                        style={{ width: "50%" }}
                        value={filter.value}
                        onChange={(e) => {
                            changeFilterValue(ruleIndex, e.target.value)
                        }}
                    />
                )
            case "BOOLEAN":
                return (
                    <Select
                        placeholder={`Select ${field.type} value...`}
                        style={{ width: "50%" }}
                        value={filter.value}
                        onChange={(value) => {
                            changeFilterValue(ruleIndex, value)
                        }}
                    >
                        <Option value="true">
                            <CheckCircleFilled />
                            Yes
                        </Option>
                        <Option value="false">
                            <WarningFilled />
                            No
                        </Option>
                    </Select>
                )

            case "ENUM":
                return (
                    <Select
                        placeholder={`Select ${field.type} value...`}
                        style={{ width: "50%" }}
                        value={filter.value}
                        onChange={(value) => {
                            changeFilterValue(ruleIndex, value)
                        }}
                    >
                        {projectConfig.enums?.[field.enumName!].map((t) => {
                            return (
                                <Option value={t} key={t}>
                                    {t}
                                </Option>
                            )
                        })}
                    </Select>
                )
            case "DATE":
                return (
                    <DatePicker
                        format="YYYY-MM-DD"
                        placeholder={`Set date value...`}
                        style={{ width: "50%" }}
                        value={dayjs(filter.value, "YYYY-MM-DD")}
                        showNow={false}
                        onChange={(date) => {
                            changeFilterValue(ruleIndex, date?.toISOString() ?? "")
                        }}
                    />
                )
            case "TIME":
                return (
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime={{}}
                        placeholder="Set date and time value..."
                        style={{ width: "50%" }}
                        value={dayjs(filter.value, "YYYY-MM-DD HH:mm:ss")}
                        showNow={false}
                        onChange={(date) => {
                            changeFilterValue(ruleIndex, date?.toISOString() ?? "")
                        }}
                    />
                )
            default:
                console.error("Unknown field type", field?.type)
                return <Input disabled placeholder="Unknown field type" />
        }
    }
}

/** React component which allows user to construct GQL query filters by adding and handling group of rules or nested groups of rules with AND, OR conditions.
 * State of this react component is controlled from its parent wrapper and provided as prop.
 * Each rule should has 3 inputs: antd selector of field, selector of rule (ex. "Equals", "Greateer than") and value.
 * Each rule can contain groups inside it recursively
 */
export const FilterRules = (props: FilterRulesPropsExtended) => {
    const { projectConfig, filterFields, filters, addFilterRule, removeFilterRule, changeFilterField, changeFilterOperator, changeFilterValue } =
        props

    return (
        <div>
            {filters?.map((filter, ruleIndex) => {
                const operators = [
                    // TODO: add more operators from EndpointFilter.operators
                    "EQUAL",
                    "IN",
                ]
                const currentField = filterFields.find((f) => {
                    if (f.name === filter.field) return true
                    if (f.connectionUnderlyingField?.name === filter.field) return true
                    return false
                })
                if (currentField?.isArray) operators.push("CONTAINS")

                // console.log("currentField", currentField)

                return (
                    <FilterItem key={ruleIndex}>
                        <Select
                            placeholder="Select field..."
                            title="Select field..."
                            allowClear
                            style={{ width: "30%" }}
                            value={filter.field}
                            onSelect={(value) => {
                                changeFilterField(ruleIndex, value)
                            }}
                        >
                            {filterFields
                                .filter(
                                    (ff) =>
                                        filters.findIndex((f) => f.field === ff.name) == -1 &&
                                        filters.findIndex((f) => f.field === ff.connectionUnderlyingField?.name) == -1
                                )
                                .map((t) => {
                                    return (
                                        <>
                                            {["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(t.type) && t.connectionUnderlyingField && (
                                                <Option value={t.connectionUnderlyingField.name} key={t.connectionUnderlyingField.name}>
                                                    {t.connectionUnderlyingField.name}
                                                </Option>
                                            )}
                                            {[
                                                "STRING",
                                                "INT",
                                                "FLOAT",
                                                "DOUBLE",
                                                "BOOLEAN",
                                                "DATE",
                                                "TIME",
                                                "ENUM",
                                                "USER_ID",
                                                "OBJECT_ID",
                                                "EMAIL",
                                                "PHONENUMBER",
                                                "USERSELF",
                                            ].includes(t.type) && (
                                                <Option value={t.name} key={t.name}>
                                                    {t.name}
                                                </Option>
                                            )}
                                        </>
                                    )
                                })}
                        </Select>

                        <Select
                            // placeholder="Select operator..."
                            // allowClear
                            disabled={!filter.field}
                            style={{ width: "20%" }}
                            value={filter.operator}
                            onSelect={(value) => {
                                changeFilterOperator(ruleIndex, value)
                            }}
                        >
                            {operators.map((t) => {
                                return (
                                    <Option value={t} key={t}>
                                        {t}
                                    </Option>
                                )
                            })}
                        </Select>

                        {filter.field ? (
                            renderValueInput(projectConfig, filter, ruleIndex, changeFilterValue, currentField)
                        ) : (
                            <Input disabled placeholder="< < Select field" style={{ width: "50%" }} />
                        )}

                        <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={() => removeFilterRule(ruleIndex)} />
                    </FilterItem>
                )
            })}
            <Button type="primary" icon={<PlusCircleFilled />} onClick={(e) => addFilterRule()}>
                Add filter rule
            </Button>
        </div>
    )
}

const FilterItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${Spaces.medium};
    align-items: center;
    margin-bottom: 10px;
`
