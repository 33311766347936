import { useState, useEffect, Fragment } from "react"
import styled from "styled-components"
import { Input, Dropdown, Button, Tooltip, Select, Popconfirm } from "antd"
import {
    EllipsisOutlined,
    CheckOutlined,
    DeleteOutlined,
    ReloadOutlined,
    RollbackOutlined,
    CheckCircleFilled,
    CloseCircleOutlined,
    UserOutlined,
    DeploymentUnitOutlined,
    BarsOutlined,
    ShareAltOutlined,
} from "@ant-design/icons"
import { getSingular } from "mypluralize"

import { Colors, Spaces, StyleHelpers } from "../global"
import type { FieldFormType } from "../../machines/DesignerModel"
import { SwitchersGroup, Switcher } from "../Switcher"
import { getTypeIcon, getTypeText, getTypeTooltipText } from "./DataField"
import { Config, ConfigEntity, EntityField, User } from "../../coreTypes/config"
import { ButtonGroupWrapper, SecondaryButton } from "./DataEntitiesTab/EntitiesFormWithFields"
import { cleanInputNameStartingLower } from "../../helpers/functions"
import { NotificationInstance } from "antd/es/notification/interface"

const { TextArea } = Input
const { Option, OptGroup } = Select

function getVisibleFieldType(field: EntityField, projectConfig: Config) {
    switch (field.type) {
        case "STRING":
        case "INT":
        case "FLOAT":
        case "DOUBLE":
        case "BOOLEAN":
        case "DATE":
        case "TIME":
        case "USER_ID":
        case "OBJECT_ID":
        case "EMAIL":
        case "PHONENUMBER":
        case "USERSELF":
            return field.type
        case "ENUM":
            return "__enum__" + field.enumName
        case "SUBOBJECT":
            return "__subObject__" + field.subObjectName
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
            if (projectConfig.entities.find((entity) => entity.entityName === field.connectedEntityName))
                return "__entity__" + field.connectedEntityName
            else if (projectConfig.users.find((userGroup) => userGroup.groupName === field.connectedEntityName))
                return "__user__" + field.connectedEntityName
            else return undefined
        // case "CUSTOMENDPOINT":
        //     return "Endpoint"
        // case "EXTERNALENDPOINT":
        //     return "External endpoint"
    }
}

export type FieldFormProps = {
    fieldForm: FieldFormType
    changeName: (fieldName: string) => void
    sendDeleteField: () => void
    sendResetField: () => void
    sendCancelField: () => void
    sendSaveField: () => void
    changeDescription: (fieldDescription: string) => void
    changeType: (fieldType: string) => void
    switchMandatory: () => void
    switchIsArray: () => void
    switchMutable: () => void
    changeConnectionUnderlyingFieldName: (fieldName: string) => void
    setRemoteField: (fieldName: string) => void
    switchUnderlyingMandatory: () => void
    switchUnderlyingIsArray: () => void
    swithcUnderlyingMutable: () => void
    mandatory?: boolean
    isUserGroupForm?: boolean
    notificationsApi?: NotificationInstance
}

type FieldFormPropsExtended = FieldFormProps & {
    projectConfig: Config
    fields: EntityField[]
}

export default function FieldForm(props: FieldFormPropsExtended) {
    const {
        projectConfig,
        fields,
        changeName,
        sendDeleteField,
        sendResetField,
        sendCancelField,
        sendSaveField,
        changeDescription,
        changeType,
        switchMandatory,
        switchIsArray,
        switchMutable,
        changeConnectionUnderlyingFieldName,
        switchUnderlyingMandatory,
        switchUnderlyingIsArray,
        swithcUnderlyingMutable,
        setRemoteField,
        mandatory,
        isUserGroupForm,
        notificationsApi,
    } = props
    const { originalField, field, formType, parentFieldUsedinEntityFieldNames } = props.fieldForm
    const { name, description } = field
    const [isHovered, setIsHovered] = useState(false)
    const [nameValue, setNameValue] = useState(name)

    const [connectionUnderlyingFieldName, setConnectionUnderlyingFieldNameValue] = useState(field.connectionUnderlyingField?.name)
    useEffect(() => {
        if (field.connectionUnderlyingField?.name) setConnectionUnderlyingFieldNameValue(field.connectionUnderlyingField.name)
    }, [props.fieldForm.field.connectionUnderlyingField?.name])

    useEffect(() => {
        setNameValue(name)
    }, [name])

    const nameEndsTest = (name: string) => {
        if (getSingular(name) !== name) {
            return true
        }
        return name.endsWith("id") || name.endsWith("Id") || name.endsWith("ID") || name.endsWith("iD")
    }

    const menuItems: any = []
    if (originalField)
        menuItems.push({
            key: "delete",
            label: (
                <Popconfirm title="Are you sure you want to delete?" onConfirm={sendDeleteField} okText="Yes" cancelText="No">
                    delete
                </Popconfirm>
            ),
            icon: <DeleteOutlined />,
        })

    if (originalField && JSON.stringify(originalField) !== JSON.stringify(field))
        menuItems.push({
            key: "reset",
            label: (
                <Popconfirm title="Are you sure you want to reset without saving changes?" onConfirm={sendResetField} okText="Yes" cancelText="No">
                    reset
                </Popconfirm>
            ),
            icon: <ReloadOutlined />,
        })

    const menuItemAction = (menuItem: any) => {
        switch (menuItem.key) {
            case "delete":
                sendDeleteField()
                break
            case "reset":
                sendResetField()
                break
        }
    }

    // const reducedEntities = projectConfig.entities.filter((e) => fields.findIndex((f) => f.connectedEntityName === e.entityName) === -1) ?? []
    // const reducedUsers = projectConfig.users.filter((e) => fields.findIndex((f) => f.connectedEntityName === e.groupName) === -1) ?? []

    // Allow more than one relationional reference of the specific entity
    const reducedEntities = projectConfig.entities
    const reducedUsers = projectConfig.users

    let optionValue
    if (field.connectedEntityName) {
        if (projectConfig.entities.find((entity) => entity.entityName === field.connectedEntityName))
            optionValue = "__entity__" + field.connectedEntityName
        else if (projectConfig.users.find((userGroup) => userGroup.groupName === field.connectedEntityName))
            optionValue = "__user__" + field.connectedEntityName
    }

    const getAllRemoteFields = (connectedEntityName: string | undefined) => {
        const result: string[] = []
        if (!connectedEntityName) return result

        const connectedEntity = projectConfig.entities.find((entity) => entity.entityName === connectedEntityName)
        const connectedUserGroup = projectConfig.users.find((userGroup) => userGroup.groupName === connectedEntityName)

        if (connectedEntity) {
            Object.keys(connectedEntity.defaultFields).forEach((df) => result.push(df))
            connectedEntity.fields.forEach((cf) => {
                if (!["CONNECTION_CONTAINS", "CONNECTION_BELONGSTO"].includes(cf.type)) {
                    result.push(cf.name)
                }
                if (cf.type === "CONNECTION_BELONGSTO" && cf.connectionUnderlyingField) {
                    result.push(`${cf.name}.${cf.connectionUnderlyingField.name}`)
                }
            })
        }

        if (connectedUserGroup) {
            Object.keys(connectedUserGroup.userData.defaultFields).forEach((df) => result.push(df))
            connectedUserGroup.userData.fields.forEach((cf) => {
                if (!["CONNECTION_CONTAINS", "CONNECTION_BELONGSTO"].includes(cf.type)) {
                    result.push(cf.name)
                }
                if (cf.type === "CONNECTION_BELONGSTO" && cf.connectionUnderlyingField) {
                    result.push(`${cf.name}.${cf.connectionUnderlyingField.name}`)
                }
            })
        }

        return result
    }

    const isSavable = (originalField && JSON.stringify(originalField) !== JSON.stringify(field)) || !originalField

    const idObj =
        name && field.type
            ? {
                  "data-intercom-target": "save-field", // TODO: add more intercom targets
              }
            : {}

    return (
        <FieldFormWrapper isHovered={isHovered}>
            <FieldFormHeader>
                <Input
                    // disabled TODO: disable if there are objects in DB with such field and show tooltip
                    placeholder="Field name"
                    title="Field name"
                    size="large"
                    value={nameValue}
                    autoFocus={name ? false : true}
                    onBlur={(e) => {
                        changeName(e.target.value)
                        const cleanName = cleanInputNameStartingLower(e.target.value)
                        if (cleanName != e.target.value) {
                            notificationsApi?.error({
                                message: "Error",
                                description: "Name cannot have spaces or numbers. Please use " + cleanName + " name instead...",
                                placement: "bottomLeft",
                                duration: 5,
                            })
                        }
                        setNameValue(cleanInputNameStartingLower(e.target.value))
                        if (nameEndsTest(nameValue)) {
                            notificationsApi?.error({
                                message: "Error",
                                description: "Field names cannot be plural or end with 'id'. Please choose a different name.",
                                placement: "bottomLeft",
                                duration: 7,
                            })
                        }
                    }}
                    onChange={(e) => {
                        setNameValue(e.target.value)
                    }}
                />
                <ButtonGroupWrapper>
                    {menuItems.length > 1 && !mandatory && (
                        <MoreWrapper>
                            <Dropdown
                                menu={{
                                    items: menuItems,
                                }}
                                placement="bottomRight"
                            >
                                <EllipsisOutlined
                                    onMouseEnter={() => {
                                        setIsHovered(true)
                                    }}
                                    onMouseLeave={() => {
                                        setIsHovered(false)
                                    }}
                                    style={{ fontSize: "20px", color: Colors.grayDark }}
                                />
                            </Dropdown>
                        </MoreWrapper>
                    )}
                    {menuItems.length == 1 && !isSavable && (
                        <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                            <Popconfirm
                                title="Are you sure you want to perform this action?"
                                onConfirm={() => {
                                    menuItemAction(menuItems[0])
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="text" shape="circle" icon={menuItems[0].icon} />
                            </Popconfirm>
                        </MoreWrapper>
                    )}

                    {isSavable && (
                        <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                            <Popconfirm
                                title="Are you sure you want to cancel without saving changes?"
                                onConfirm={sendCancelField}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="text" shape="circle" icon={<RollbackOutlined />} />
                            </Popconfirm>
                        </MoreWrapper>
                    )}

                    <SecondaryButton
                        {...idObj}
                        isSolid={isSavable}
                        type="primary"
                        shape="round"
                        icon={isSavable ? <CheckOutlined /> : <RollbackOutlined />}
                        disabled={
                            !name ||
                            !field.type ||
                            (field.connectionUnderlyingField && !field.connectionUnderlyingField.name) ||
                            nameEndsTest(nameValue)
                        }
                        onMouseEnter={() => {
                            setIsHovered(true)
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false)
                        }}
                        onClick={() => {
                            setIsHovered(false)
                            sendSaveField()
                        }}
                    >
                        {isSavable ? "Save" : "Back"}
                    </SecondaryButton>
                </ButtonGroupWrapper>
            </FieldFormHeader>

            <FieldFormBody>
                <TextArea
                    placeholder="(optional) Corresponding description of the field..."
                    title="Corresponding description of the field..."
                    value={description}
                    onChange={(e) => {
                        changeDescription(e.target.value)
                    }}
                    autoSize={{ minRows: 2, maxRows: 5 }}
                />

                <Select
                    placeholder="Select type..."
                    title="Select type..."
                    style={{ width: 300 }}
                    allowClear
                    value={getVisibleFieldType(field, projectConfig)}
                    onSelect={(value) => {
                        changeType(value)
                    }}
                >
                    <OptGroup label="Common types" key="common">
                        {[
                            "STRING",
                            "INT",
                            "FLOAT",
                            "DOUBLE",
                            "BOOLEAN",
                            "DATE",
                            "TIME",
                            // "CUSTOMENDPOINT",
                            // "EXTERNALENDPOINT",
                            "EMAIL",
                            "PHONENUMBER",
                            // "USERSELF", // TODO: "ANYUSER" to allow any user to be set with underlying field = "USERSELF"
                            // other types are not relevant here
                        ].map((t) => {
                            let optionItem = (
                                <OptionItemWrapper>
                                    {getTypeIcon(t)}
                                    {getTypeText(t)}
                                </OptionItemWrapper>
                            )
                            const tooltipText = getTypeTooltipText(t)
                            if (tooltipText) {
                                optionItem = (
                                    <Tooltip mouseEnterDelay={0.4} title={tooltipText} overlayInnerStyle={{ width: "600px" }}>
                                        {optionItem}
                                    </Tooltip>
                                )
                            }
                            return (
                                <Option value={t} key={t}>
                                    {optionItem}
                                </Option>
                            )
                        })}
                    </OptGroup>

                    <OptGroup label="Project relation entities/users" key="entities">
                        {originalField?.connectedEntityName &&
                            reducedEntities.findIndex((re) => re.entityName === originalField.connectedEntityName) === -1 &&
                            reducedUsers.findIndex((u) => u.groupName === originalField.connectedEntityName) === -1 && (
                                <Option value={optionValue} key={originalField.connectedEntityName}>
                                    <OptionItemWrapper>
                                        <DeploymentUnitOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                        {originalField.connectedEntityName}
                                    </OptionItemWrapper>
                                </Option>
                            )}

                        {reducedUsers.map((e: User) => (
                            <Option value={"__user__" + e.groupName} key={e.groupName}>
                                <OptionItemWrapper>
                                    <UserOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                    {e.groupName}
                                </OptionItemWrapper>
                            </Option>
                        ))}

                        {reducedEntities.map((e: ConfigEntity) => (
                            <Option value={"__entity__" + e.entityName} key={e.entityName}>
                                <OptionItemWrapper>
                                    <DeploymentUnitOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                    {e.entityName}
                                </OptionItemWrapper>
                            </Option>
                        ))}

                        {!field.connectedEntityName && reducedUsers.length === 0 && reducedEntities.length === 0 && (
                            <Option disabled value="">
                                Create entity or user to select...
                            </Option>
                        )}
                    </OptGroup>

                    <OptGroup label="Project sub-objects" key="subObjects">
                        {projectConfig.subObjects &&
                            Object.keys(projectConfig.subObjects).map((e: string) => (
                                <Option value={"__subObject__" + e} key={e}>
                                    <OptionItemWrapper>
                                        <ShareAltOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                        {e}
                                    </OptionItemWrapper>
                                </Option>
                            ))}
                        {(!projectConfig.subObjects || Object.keys(projectConfig.subObjects).length === 0) && (
                            <Option disabled value="" key="no-subObjects">
                                Create sub-object to select...
                            </Option>
                        )}
                    </OptGroup>

                    <OptGroup label="Project enums" key="enums">
                        {projectConfig.enums &&
                            Object.keys(projectConfig.enums).map((e: string) => (
                                <Option value={"__enum__" + e} key={e}>
                                    <OptionItemWrapper>
                                        <BarsOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                        {e}
                                    </OptionItemWrapper>
                                </Option>
                            ))}
                        {(!projectConfig.enums || Object.keys(projectConfig.enums).length === 0) && (
                            <Option disabled value="" key="no-enums">
                                Create enum to select...
                            </Option>
                        )}
                    </OptGroup>
                </Select>

                {field.type && !["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(field.type) && (
                    <SwitchersGroup>
                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={`This field is currently ${field.mandatory ? "mandatory" : "optional"} when creating object.`}
                        >
                            {!field.mandatory && parentFieldUsedinEntityFieldNames && formType === "SubObjectForm" ? (
                                <Popconfirm
                                    title={
                                        <>
                                            This sub-object is used within several entities the following entities of the project.
                                            <br />
                                            <br />
                                            {parentFieldUsedinEntityFieldNames.map((name, index) => (
                                                <Fragment key={index}>
                                                    {`"${name}"`}
                                                    <br />
                                                </Fragment>
                                            ))}
                                            <br />
                                            By making it mandatory, you'll need to manually update access permissions
                                            <br />
                                            for each entity where it's used.
                                        </>
                                    }
                                    onConfirm={switchMandatory} // Trigger the switchMandatory function on confirmation
                                    cancelText="Keep optional"
                                    okText="Make mandatory anyway"
                                    placement="topLeft"
                                >
                                    <Switcher selected={false}>
                                        {/* <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} /> */}
                                        Make mandatory
                                    </Switcher>
                                </Popconfirm>
                            ) : (
                                <Switcher selected={field.mandatory} onClick={switchMandatory}>
                                    {
                                        field.mandatory && <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                        // : (
                                        //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                        // )
                                    }
                                    {field.mandatory ? "is mandatory" : "Make mandatory"}
                                </Switcher>
                            )}
                        </Tooltip>
                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={`This field ${
                                field.isArray ? "can" : "cannot"
                            } contain more than one value (An array is a way of storing a collection of values or items in a specific order).`}
                        >
                            <Switcher selected={field.isArray} onClick={switchIsArray}>
                                {
                                    field.isArray && <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                    // : (
                                    //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                    // )
                                }
                                {field.isArray ? "is array" : "Make array"}
                            </Switcher>
                        </Tooltip>
                        <Tooltip
                            mouseEnterDelay={0.4}
                            arrow={{ pointAtCenter: true }}
                            overlayInnerStyle={{ width: "600px" }}
                            title={
                                field.mutable
                                    ? `(enabled) When something is described as "mutable", it means that it can be changed or altered later after it was initially created. Think about a recipe for a soup. If you want to change the flavor or the ingredients, you can modify the recipe by adding or removing ingredients, changing the cooking time or temperature, or adjusting the proportions of the elements. In this case, the recipe is a mutable object because it can be changed by altering its components and characteristics.`
                                    : `Immutable (opposite of mutable) means that data cannot be modified after it was created.`
                            }
                        >
                            <Switcher selected={field.mutable} onClick={switchMutable}>
                                {
                                    field.mutable && <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                    // : (
                                    //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                    // )
                                }
                                {field.mutable ? "is mutable" : "Make mutable"}
                            </Switcher>
                        </Tooltip>

                        {/* TODO: implement default value + min/max + relative values + date range */}
                        <Tooltip
                            mouseEnterDelay={0.4}
                            arrow={{ pointAtCenter: true }}
                            // overlayInnerStyle={{ width: "300px" }}
                            title={`"Automatic default value" option coming soon...`}
                        >
                            <Switcher
                                selected={false}
                                inactive
                                // onClick={() => {}}
                            >
                                {false ? (
                                    <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                ) : (
                                    <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayNormal }} />
                                )}
                                default value
                            </Switcher>
                        </Tooltip>
                    </SwitchersGroup>
                )}

                {field.type && ["CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(field.type) && field.connectionUnderlyingField && (
                    <ConnectionField>
                        <ConnectionFieldHeader>
                            <DeploymentUnitOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                            <span className="text">Connection underlying field</span>
                        </ConnectionFieldHeader>

                        <ConnetionFieldRemoteFieldWrapper>
                            <ConnectionFieldInput
                                disabled // TODO: enable when allow more types apart from default
                                // TODO: disable if there are objects in DB with such field and show tooltip
                                placeholder={`Underlying field name (ex. "${field.name}Id")`}
                                title={`Underlying field name (ex. "${field.name}Id")`}
                                style={{ width: 275 }}
                                value={connectionUnderlyingFieldName}
                                onBlur={(e) => {
                                    changeConnectionUnderlyingFieldName(e.target.value)
                                }}
                                onChange={(e) => {
                                    setConnectionUnderlyingFieldNameValue(e.target.value)
                                }}
                            />

                            <ConnetionFieldRemoteField>
                                <span className="equal">=</span>

                                <Tooltip
                                    mouseEnterDelay={0.4}
                                    arrow={{ pointAtCenter: true }}
                                    // overlayInnerStyle={{ width: "300px" }}
                                    title={`"Remote field to look by" coming soon...`}
                                >
                                    <Select
                                        placeholder="Select remote field"
                                        style={{ width: 275 }}
                                        allowClear
                                        value={field.connectionUnderlyingField?.remoteEntityConnectionFieldName}
                                        onSelect={(value) => {
                                            setRemoteField(value)
                                        }}
                                        dropdownStyle={{ maxWidth: "300px", whiteSpace: "nowrap", overflowX: "auto" }}
                                    >
                                        <OptGroup label="Underlying field type" key="common">
                                            {getAllRemoteFields(field.connectedEntityName).map((t) => {
                                                return (
                                                    <Option value={t} key={t}>
                                                        <OptionItemWrapper>{`${field.connectedEntityName}.${t}`}</OptionItemWrapper>
                                                    </Option>
                                                )
                                            })}
                                        </OptGroup>
                                    </Select>
                                </Tooltip>
                            </ConnetionFieldRemoteField>
                        </ConnetionFieldRemoteFieldWrapper>

                        <ConnectionFieldOptions>
                            {/* <Select
                                placeholder="Select underlying field type..."
                                style={{ width: 275 }}
                                disabled
                                allowClear
                                value={field.connectionUnderlyingField.type}
                                onSelect={(value) => {
                                    // setType(value) FIXME: handle on the back-end USERSELF mutations
                                }}
                            >
                                <OptGroup label="Underlying field type" key="common">
                                    {[
                                        // FIXME: allow only relevant types based on connection type
                                        "OBJECT_ID",
                                        "USER_ID",
                                        "USERSELF",
                                        // other types are not relevant here
                                    ].map((t) => {
                                        let optionItem = (
                                            <OptionItemWrapper>
                                                {getTypeIcon(t)}
                                                {getTypeText(t)}
                                            </OptionItemWrapper>
                                        )
                                        const tooltipText = getTypeTooltipText(t)
                                        if (tooltipText) {
                                            optionItem = (
                                                <Tooltip mouseEnterDelay={0.4} title={tooltipText} overlayInnerStyle={{ width: "300px" }}>
                                                    {optionItem}
                                                </Tooltip>
                                            )
                                        }
                                        return (
                                            <Option value={t} key={t}>
                                                {optionItem}
                                            </Option>
                                        )
                                    })}
                                </OptGroup>
                            </Select> */}

                            {/* TODO: automatically disable/hide and set switchers for USERSELF type */}
                            {/* FIXME: reuse switcher block with the above one */}
                            <SwitchersGroup>
                                <Tooltip
                                    mouseEnterDelay={0.4}
                                    title={`This field is ${
                                        field.connectionUnderlyingField.mandatory ? "mandatory" : "optional"
                                    } when creating object. ${isUserGroupForm ? "(not allowed to be mandatory when creating user)" : ""}`}
                                >
                                    <Switcher
                                        inactive={isUserGroupForm}
                                        selected={field.connectionUnderlyingField.mandatory}
                                        onClick={() => !isUserGroupForm && switchUnderlyingMandatory()}
                                    >
                                        {
                                            field.connectionUnderlyingField.mandatory && (
                                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                            )
                                            // : (
                                            //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                            // )
                                        }
                                        {field.connectionUnderlyingField.mandatory ? "is mandatory" : "Make mandatory"}
                                    </Switcher>
                                </Tooltip>
                                <Tooltip
                                    mouseEnterDelay={0.4}
                                    title={`This field ${
                                        field.connectionUnderlyingField.isArray ? "can" : "cannot"
                                    } contains more than one value. An array is a way of storing a collection of values or items in a specific order.`}
                                >
                                    <Switcher selected={field.connectionUnderlyingField.isArray} onClick={switchUnderlyingIsArray}>
                                        {
                                            field.connectionUnderlyingField.isArray && (
                                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                            )
                                            // : (
                                            //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                            // )
                                        }
                                        {field.connectionUnderlyingField.isArray ? "is array" : "Make array"}
                                    </Switcher>
                                </Tooltip>
                                <Tooltip
                                    mouseEnterDelay={0.4}
                                    arrow={{ pointAtCenter: true }}
                                    overlayInnerStyle={{ width: "600px" }}
                                    title={
                                        field.connectionUnderlyingField.mutable
                                            ? `(enabled) When something is described as "mutable", it means that it can be changed or altered later after it was initially created. Think about a recipe for a soup. If you want to change the flavor or the ingredients, you can modify the recipe by adding or removing ingredients, changing the cooking time or temperature, or adjusting the proportions of the elements. In this case, the recipe is a mutable object because it can be changed by altering its components and characteristics.`
                                            : `Immutable (opposite of mutable) means that data cannot be modified after it was created.`
                                    }
                                >
                                    <Switcher selected={field.connectionUnderlyingField.mutable} onClick={swithcUnderlyingMutable}>
                                        {
                                            field.connectionUnderlyingField.mutable && (
                                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                                            )
                                            // : (
                                            //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                                            // )
                                        }
                                        {field.connectionUnderlyingField.mutable ? "is mutable" : "Make mutable"}
                                    </Switcher>
                                </Tooltip>
                            </SwitchersGroup>
                        </ConnectionFieldOptions>
                    </ConnectionField>
                )}
            </FieldFormBody>
        </FieldFormWrapper>
    )
}

const FieldFormWrapper = styled.div<{ isHovered: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${Spaces.large};
    gap: ${Spaces.medium};

    width: 100%;
    border-radius: ${StyleHelpers.radiusSmall};
    border: 2px solid ${Colors.primary};
    box-shadow: ${StyleHelpers.accentGlowShadow};
    /* background-color: ${(props: { isHovered: boolean }) => (props.isHovered ? Colors.grayLight : Colors.background)}; */
    background-color: white;
`

const FieldFormHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    justify-content: space-between;

    width: 100%;
    input {
        max-width: 300px;
    }
`

const MoreWrapper = styled.div`
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const FieldFormBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: ${Spaces.medium};

    width: 100%;

    textarea {
        max-width: 500px;
    }
`

const OptionItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.small};
`

const ConnectionField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${Spaces.normal};
`

const ConnectionFieldHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.small};
    span.text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
    }
`

const ConnectionFieldInput = styled(Input)`
    /* margin-left: ${Spaces.xLarge}; */
`

const ConnectionFieldOptions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.large};
    /* margin-left: ${Spaces.xLarge}; */
`

const ConnetionFieldRemoteFieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    span.equal {
        font-size: 18px;
        font-weight: 600;
        margin: 0px 4px;
    }
`

const ConnetionFieldRemoteField = styled.div``

export { MoreWrapper, OptionItemWrapper }
