import { useEffect, useState } from "react"
import styled from "styled-components"
import { Tabs, Result, Typography, Tree, Button, Tooltip, Table, Modal, notification, Dropdown, MenuProps, Empty } from "antd"
import {
    CodeOutlined,
    FieldTimeOutlined,
    CloseCircleOutlined,
    LoadingOutlined,
    HistoryOutlined,
    NodeExpandOutlined,
    TableOutlined,
    UserOutlined,
    CopyOutlined,
    CheckOutlined,
    FontColorsOutlined,
    FieldNumberOutlined,
    FieldBinaryOutlined,
    EyeFilled,
    BarsOutlined,
    NumberOutlined,
    CalendarOutlined,
    ClockCircleOutlined,
    ShareAltOutlined,
    ApiOutlined,
    MailOutlined,
    PhoneOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"

import { StyleHelpers, Colors, Spaces, Centered } from "../../global"
import { EndpointData } from "../../../machines/SimulatorModel"
import { TreeWrapper } from "../../Designer/AccessPermissions/AllowedFieldsTree"
import { getLocalTime, isUUID } from "../../../helpers/functions"
import { Config } from "../../../coreTypes/config"

const { Title, Paragraph, Text } = Typography

interface FlattenedRow {
    [key: string]: string | JSX.Element
}

// Define a union type for the valid keys of typeIconMapping
type ValidTypeKeys =
    | "STRING"
    | "INT"
    | "FLOAT"
    | "DOUBLE"
    | "BOOLEAN"
    | "DATE"
    | "TIME"
    | "ENUM"
    | "SUBOBJECT"
    | "USER_ID"
    | "OBJECT_ID"
    | "EMAIL"
    | "PHONENUMBER"
    | "ARRAY"
    | "USER_STATUS"
    | "DEFAULT"

// Icon mapping for different types
const typeIconMapping: Record<ValidTypeKeys, JSX.Element> = {
    STRING: <FontColorsOutlined />,
    INT: <FieldNumberOutlined />,
    ARRAY: <BarsOutlined />,
    BOOLEAN: <FieldBinaryOutlined />,
    FLOAT: <NumberOutlined />,
    DOUBLE: <NumberOutlined />,
    DATE: <CalendarOutlined />,
    TIME: <ClockCircleOutlined />,
    ENUM: <BarsOutlined />,
    SUBOBJECT: <ShareAltOutlined />,
    USER_ID: <ApiOutlined />,
    OBJECT_ID: <ApiOutlined />,
    EMAIL: <MailOutlined />,
    PHONENUMBER: <PhoneOutlined />,
    USER_STATUS: <FieldBinaryOutlined />,
    DEFAULT: <FontColorsOutlined />,
}

const ResponseContainer = (props: {
    isLoading: boolean
    response: EndpointData["response"]
    type: string
    config: Config
    selectedFields: string[] | undefined
    selectedEndpoint: string
}) => {
    const { response, type, isLoading, config, selectedFields, selectedEndpoint } = props

    if (!response) return null

    const [now, setNow] = useState(new Date())
    const [copied, setCopied] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    // Timer to update the current time
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date())
        }, 100)
        if (response.endTime) return () => clearInterval(interval)
    }, [response.startTime, response.endTime])

    // Function to handle copying the value
    const handleCopyValue = (value: any) => {
        const stringValue = typeof value === "object" ? JSON.stringify(value) : value // Convert to string if it's an object
        navigator.clipboard
            .writeText(stringValue)
            .then(() => {
                // Show success message on copy
                notification.success({
                    message: "Copied!",
                    description: "The cell value has been copied to the clipboard successfully.",
                    placement: "topRight", // Can be changed to 'topLeft', 'bottomRight', 'bottomLeft'
                })
            })
            .catch(() => {
                // Show error message if something goes wrong
                notification.error({
                    message: "Failed to copy!",
                    description: "The cell value has been NOT copied.",
                    placement: "topRight", // Can be changed to 'topLeft', 'bottomRight', 'bottomLeft'
                })
            })
    }

    const convertToDisplayData = (value: string) => {
        let result = value
        if (dayjs(value).isValid()) {
            result = getLocalTime(value)
        }
        if (isUUID(value)) {
            result = `${value.slice(0, 3)}...${value.slice(-3)}`
        }
        return result
    }

    const flattenMenuData = (objs: any[], parentKey = ""): MenuProps["items"] => {
        const result: MenuProps["items"] = []
        objs.forEach((obj, pIdx) => {
            if (typeof obj === "object") {
                const subResult: MenuProps["items"] = []
                Object.keys(obj).forEach((key, cIdx) => {
                    if (typeof obj[key] === "object") {
                        if (Array.isArray(obj[key])) {
                            subResult.push({
                                key: `${pIdx}-${cIdx}-${key}`,
                                type: "group",
                                label: key,
                                children: flattenMenuData(obj[key], `${pIdx}-${cIdx}-${key}`),
                            })
                        } else {
                            subResult.push({
                                key: `${pIdx}-${cIdx}-${key}`,
                                type: "group",
                                label: key,
                                children: flattenMenuData([obj[key]], `${pIdx}-${cIdx}-${key}`),
                            })
                        }
                    } else {
                        subResult.push({
                            key: `${pIdx}-${cIdx}-${key}`,
                            type: "group",
                            label: key,
                            children: [{ key: `${pIdx}-${key}-${cIdx}-0`, label: convertToDisplayData(obj[key]) }],
                        })
                    }
                })
                result.push({
                    key: `${parentKey}-${pIdx}`,
                    label: `Item ${pIdx + 1}`,
                    children: subResult,
                })
            } else {
                result.push({
                    key: `${parentKey}-${pIdx}`,
                    label: convertToDisplayData(obj),
                })
            }
        })
        return result
    }

    // Flattening function modified to include the click handler for table cells
    const flattenTableData = (obj: any, parentKey = ""): FlattenedRow => {
        let result: FlattenedRow = {}
        if (!obj) return result
        Object.keys(obj).forEach((key) => {
            const newKey = parentKey ? `${parentKey}.${key}` : key

            if (typeof obj[key] === "object") {
                if (!Array.isArray(obj[key])) {
                    result = { ...result, ...flattenTableData(obj[key], newKey) }
                } else {
                    result[newKey] = (
                        <Dropdown
                            key={key}
                            menu={{
                                items: [
                                    {
                                        key,
                                        type: "group",
                                        label: `${obj[key].length} items`,
                                        children: flattenMenuData(obj[key], key),
                                    },
                                ],
                                onClick: (e) => handleCopyValue((e.domEvent.target as HTMLElement).innerText),
                            }}
                        >
                            <EyeFilled />
                        </Dropdown>
                    )
                }
            } else {
                result[newKey] = (
                    <Tooltip title={obj[key]}>
                        <span onClick={() => handleCopyValue(obj[key])} style={{ cursor: "pointer" }}>
                            {convertToDisplayData(obj[key])}
                        </span>
                    </Tooltip>
                )
            }
        })
        return result
    }

    // Function to find the first array in the response data (for dynamic handling)
    const findArrayInResponse = (data: any): any[] => {
        if (!data || typeof data !== "object") return []

        for (const key in data) {
            if (Array.isArray(data[key])) {
                return data[key] // Return the first array found
            } else if (typeof data[key] === "object") {
                const result = findArrayInResponse(data[key]) // Recursively search nested objects
                if (result.length) {
                    return result // If an array is found in nested objects, return it
                }
            }
        }
        return []
    }

    // Use the findArrayInResponse function to get the array from response.data
    const arrayData = findArrayInResponse(response?.data)

    // Flatten the data for the table
    const flattenedTableData = arrayData.map((item: any, index: number) => ({
        "No.": index + 1, // Use index as the key
        ...flattenTableData(item),
    }))

    // Create columns dynamically from the first row's keys
    const columns = Object.keys(flattenedTableData?.at(0) || {}).map((key) => ({
        title: key,
        dataIndex: key,
        key,
        align: "center" as const,
    }))

    const getType = (config: Config, treeKey: string) => {
        let result = undefined
        const extractKey = treeKey
            .split(".")
            .filter((segment) => !segment.includes("item-") && !segment.startsWith("get") && !segment.startsWith("list"))
            .join(".")

        if (extractKey === "") return "SUBOBJECT"

        if (!selectedFields?.find((f) => f === extractKey)) return undefined
        const entityName = selectedEndpoint.startsWith("get") ? selectedEndpoint.slice(3) : selectedEndpoint.slice(4).slice(0, -1)

        const isEntity = config.entities.find((e) => e.entityName === entityName)
        const isUser = config.users.find((u) => u.groupName === entityName)
        if (!isEntity && !isUser) return undefined

        let defaultFields: any[] = []
        let allFields: any[] = []

        if (isEntity) {
            defaultFields = Object.entries(isEntity.defaultFields).map(([key, value]) => ({
                name: key === "_id" ? "id" : key,
                type: value,
            }))
            allFields = [...defaultFields, ...isEntity.fields]
        } else if (isUser) {
            defaultFields = Object.entries(isUser.userData.defaultFields).map(([key, value]) => ({
                name: key === "_id" ? "id" : key,
                type: value,
            }))
            allFields = [...defaultFields, ...isUser.userData.fields]
        }

        const keys = extractKey.split(".")
        keys.forEach((key) => {
            const field = allFields.find((af) => af.name === key)
            if (field) {
                if (field.type !== "CONNECTION_BELONGSTO" && field.type !== "CONNECTION_CONTAINS") {
                    result = field.type
                }
                if (field.type === "CONNECTION_BELONGSTO" || field.type === "CONNECTION_CONTAINS") {
                    const connectedEntity = config.entities.find((ce) => ce.entityName === field.connectedEntityName)
                    const connectedUser = config.users.find((cu) => cu.groupName === field.connectedEntityName)
                    if (connectedEntity) {
                        defaultFields = Object.entries(connectedEntity.defaultFields).map(([key, value]) => ({
                            name: key === "_id" ? "id" : key,
                            type: value,
                        }))
                        allFields = [...defaultFields, ...connectedEntity.fields]
                    }
                    if (connectedUser) {
                        defaultFields = Object.entries(connectedUser.userData.defaultFields).map(([key, value]) => ({
                            name: key === "_id" ? "id" : key,
                            type: value,
                        }))
                        allFields = [...defaultFields, ...connectedUser.userData.fields]
                    }
                }
            }
        })

        return result
    }

    // Use the ValidTypeKeys in your convertToTreeData function
    const expandItemKeys: string[] = []

    // Helper to check if it's the first array being processed
    let firstArrayProcessed = false
    const convertToTreeData = (data: any, parentKey = ""): any[] => {
        if (typeof data !== "object" || data === null) {
            return []
        }

        return Object.keys(data).map((key) => {
            const newKey = parentKey ? `${parentKey}.${key}` : key

            const valueType: ValidTypeKeys = Array.isArray(data[key]) ? "ARRAY" : ((getType(config, newKey) || "DEFAULT") as ValidTypeKeys)

            if (typeof data[key] === "object" && data[key] !== null) {
                const children = Array.isArray(data[key])
                    ? data[key].map((item: any, index: number) => {
                          const arrayItemKey = `${newKey}.item-${index}`

                          // Expand only the first item of the first array and its nested children
                          if (!firstArrayProcessed && index === 0) {
                              expandItemKeys.push(arrayItemKey) // Expand the first array's first item
                              // Check for nested objects like 'conversation' and 'from' and expand them
                              Object.keys(item).forEach((nestedKey) => {
                                  if (typeof item[nestedKey] === "object" && item[nestedKey] !== null) {
                                      expandItemKeys.push(`${arrayItemKey}.${nestedKey}`) // Expand nested objects
                                  }
                              })
                          }

                          // Display the array item with its value (for primitive values like IDs)
                          const itemDisplay = typeof item === "object" ? `Item ${index}` : `Item ${index}: ${JSON.stringify(item)}`

                          return {
                              title: itemDisplay,
                              key: arrayItemKey,
                              children: convertToTreeData(item, arrayItemKey),
                              value: JSON.stringify(item),
                          }
                      })
                    : convertToTreeData(data[key], newKey)

                // Mark the first array as processed once we hit it
                if (Array.isArray(data[key]) && !firstArrayProcessed) {
                    firstArrayProcessed = true
                }

                return {
                    title: (
                        <span>
                            {typeIconMapping[valueType]}
                            &nbsp;
                            {key}
                        </span>
                    ),
                    key: newKey,
                    children,
                }
            }

            // If the value is a primitive, display it as a leaf node
            return {
                title: (
                    <span>
                        {typeIconMapping[valueType]}
                        &nbsp;
                        {`${key}: ${JSON.stringify(data[key], null, 2)}`}
                    </span>
                ),
                key: newKey,
                value: JSON.stringify(data[key], null, 2),
            }
        })
    }

    // Function to copy the entire response to clipboard
    const copyResponseData = () => {
        const responseDataStr = JSON.stringify(response.data, null, 4) // Stringify the response data
        navigator.clipboard
            .writeText(responseDataStr)
            .then(() => {
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 2000)
            })
            .catch((err) => setCopied(false))
    }

    // Define the children for Tabs based on response type
    const TabsChildrenArr = [
        {
            key: "RAW",
            label: (
                <span>
                    <CodeOutlined style={{ marginRight: 5 }} />
                    Raw response
                </span>
            ),
            children: (
                <TabContentWrapper>
                    <div style={{ position: "relative" }}>
                        <Tooltip title="Copy Raw JSON" placement="left">
                            <Button
                                style={{ position: "absolute", top: 0, right: 0 }}
                                icon={copied ? <CheckOutlined /> : <CopyOutlined />}
                                onClick={copyResponseData}
                            />
                        </Tooltip>
                        <pre style={{ margin: 0, padding: Spaces.small }}>{JSON.stringify(response.data, null, 4)}</pre>
                    </div>
                </TabContentWrapper>
            ),
        },
    ]

    // Add table view if type is "List"
    if (type === "List") {
        TabsChildrenArr.push({
            key: "TABLE",
            label: (
                <span>
                    <TableOutlined style={{ marginRight: 5 }} />
                    Table
                </span>
            ),
            children: (
                <TabContentWrapper>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={() => setIsModalOpen(true)} style={{ marginBottom: "10px" }}>
                            Full-Screen View
                        </Button>
                        <Tooltip title="Copy JSON">
                            <Button icon={copied ? <CheckOutlined /> : <CopyOutlined />} onClick={copyResponseData} />
                        </Tooltip>
                    </div>
                    <div style={{ paddingRight: Spaces.normal, overflowX: "scroll" }}>
                        <Table dataSource={flattenedTableData} columns={columns} pagination={false} />
                        <Modal
                            title="Full-Screen Table View"
                            open={isModalOpen}
                            onCancel={() => setIsModalOpen(false)}
                            footer={null}
                            width="90%"
                            style={{ top: 20 }}
                            styles={{ body: { overflowX: "scroll" } }}
                        >
                            <Table dataSource={flattenedTableData} columns={columns} pagination={false} />
                        </Modal>
                    </div>
                </TabContentWrapper>
            ),
        })
    }

    // Tree view (if type is "List" or "Get")
    if (["List", "Get"].includes(type)) {
        const treeData = convertToTreeData(response.data)
        TabsChildrenArr.push({
            key: "TREE",
            label: (
                <span>
                    <NodeExpandOutlined style={{ marginRight: 5 }} />
                    Tree
                </span>
            ),
            children: (
                <TabContentWrapper>
                    <div style={{ position: "relative" }}>
                        <Tooltip title="Copy Tree JSON" placement="left">
                            <Button
                                style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
                                icon={copied ? <CheckOutlined /> : <CopyOutlined />}
                                onClick={copyResponseData}
                            />
                        </Tooltip>
                        {treeData.length ? (
                            <TreeWrapper>
                                <Tree
                                    showLine
                                    defaultExpandedKeys={expandItemKeys}
                                    treeData={treeData}
                                    onSelect={(selectedKeys, info) => {
                                        const { value } = info.node
                                        handleCopyValue(value)
                                    }}
                                />
                            </TreeWrapper>
                        ) : (
                            "No data available for tree view"
                        )}
                    </div>
                </TabContentWrapper>
            ),
        })
    }

    return (
        <ResponseContainerWrapper>
            {!response.endTime && isLoading && (
                <Centered vertical style={{ paddingTop: "25px" }}>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={4}>{`API call is being executed: ${dayjs(now).diff(response.startTime, "millisecond")}ms ...`}</Title>
                </Centered>
            )}

            {response.endTime && (
                <Stats>
                    <StatInfo>
                        <FieldTimeOutlined
                            style={{
                                width: StyleHelpers.iconSize,
                                color: Colors.grayDark,
                            }}
                        />{" "}
                        <span>{`Last execution: ${dayjs(response.endTime).format("DD/MM/YY hh:mm")} (${dayjs().diff(
                            response.endTime,
                            "minute"
                        )} mins ago)`}</span>
                    </StatInfo>
                    <StatInfo>
                        <HistoryOutlined
                            style={{
                                width: StyleHelpers.iconSize,
                                color: Colors.grayDark,
                            }}
                        />
                        <span>{`Duration: ${dayjs(response.endTime).diff(response.startTime, "millisecond")}ms`}</span>
                    </StatInfo>
                    <StatInfo>
                        <UserOutlined
                            style={{
                                width: StyleHelpers.iconSize,
                                color: Colors.grayDark,
                            }}
                        />
                        <span>{`On behalf: ${response.onBehalf}`}</span>
                    </StatInfo>
                </Stats>
            )}

            {response.errors && (
                <Result
                    status="error"
                    title="API call failed"
                    subTitle={`Please check and modify the request before resubmitting`}
                    // extra={[
                    //     <Button type="primary" key="console">
                    //         Go Console
                    //     </Button>,
                    // ]}
                >
                    <div className="desc">
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                The request you submitted has the following error:
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            {response.errors.map((err) => (
                                <>
                                    <CloseCircleOutlined className="site-result-demo-error-icon" /> {err.message}
                                    <br />
                                </>
                            ))}
                        </Paragraph>
                    </div>
                </Result>
            )}

            {response.data && <Tabs className="response-tabs" defaultActiveKey={"RAW"} centered items={TabsChildrenArr} />}
        </ResponseContainerWrapper>
    )
}

export default ResponseContainer

const ResponseContainerWrapper = styled.div`
    .response-tabs {
        .ant-tabs-nav {
            background-color: "unset";
            box-shadow: "unset";
        }
    }
`

const Stats = styled.div`
    margin-top: ${Spaces.medium};
    display: flex;
    flex-direction: column;
    gap: ${Spaces.small};
    padding: 0 ${Spaces.large} ${Spaces.large};
`

const StatInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.small};
    color: ${Colors.grayDark};
`

const TabContentWrapper = styled.div<{ withPadding?: boolean }>`
    overflow-y: scroll;
    height: calc(100vh - 300px);
    width: 100%;
    padding: ${Spaces.medium} ${Spaces.medium} 0;
    scrollbar-width: none;
    .ant-tree-list {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
        padding: ${Spaces.small};
    }
    .ant-tree-node-selected {
        background-color: ${Colors.transparentDarkFrostyBackground} !important;
    }
    .ant-table-wrapper {
        table {
            font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
        }
        padding: ${Spaces.small};
    }
`
