import { createModel } from "xstate/lib/model"

import type { Config } from "../coreTypes/config"
import type { Endpoint, Endpoints, SubfieldHelper } from "../coreTypes/endpoints"
import { NotificationInstance } from "antd/es/notification/interface"

type AuthItem = {
    authType: "APIKEY" | "USERGROUP"
    username: string
    authToken: string
    userGroup?: string
    refreshToken?: string
}

interface EndpointFilter {
    field: string
    // FIXME: need to implement all operators on the server side [Tasks]
    operator:
        | "EQUAL"
        // | "NOT_EQUAL"
        // | "LESS_THAN"
        // | "LESS_THAN_OR_EQUAL"
        // | "GREATER_THAN"
        // | "GREATER_THAN_OR_EQUAL"
        | "IN"
        // | "NOT_IN"
        | "CONTAINS"
    // | "NOT_CONTAINS"
    // | "STARTS_WITH"
    // | "NOT_STARTS_WITH"
    // | "ENDS_WITH"
    // | "NOT_ENDS_WITH"
    // | "BETWEEN"
    // | "NOT_BETWEEN"
    // | "IS_NULL"
    // | "IS_NOT_NULL"
    // | "IS_EMPTY"
    // | "IS_NOT_EMPTY"
    value: any
}

interface EndpointData {
    status: "" | "loading" | "success" | "error"
    objectId?: string
    filters: EndpointFilter[]
    selectorFields: string[]
    inputs: {
        [key: string]: any
    }
    preventRunErrors?: string[]
    response?: {
        startTime: Date
        endTime?: Date
        onBehalf?: string
        data?: {
            [key: string]: any
        }
        errors?: { message: string }[]
    }
    payload: {
        query: string
        variables?: {
            [key: string]: any
        }
    }
}

interface VisibleQueryEndpoint {
    type: "GET" | "LIST"
    name: string
    entityName: string
    description?: string
    isInputOutputAll: boolean
    outputFields?: string[]
    outputSubfields?: SubfieldHelper
}

interface VisibleMutationEndpoint {
    type: "CREATE" | "UPDATE" | "DELETE"
    name: string
    description?: string
    entityName: string
    isInputOutputAll: boolean
    outputFields?: string[]
    outputSubfields?: SubfieldHelper
    inputFields?: string[]
}

interface SimulatorModelContext {
    notificationsApi: undefined | NotificationInstance

    projectId: string
    projectConfig: Config
    generatedEndpoints: Endpoints
    clientTypes: string
    queries: string
    mutations: string

    apiAuth: {
        newAuthType: "APIKEY" | "USERGROUP"
        userGroup: string
        loginForm: {
            username: string
            password: string
        }
        signupForm: {
            username: string
            password: string
            invitationCode: string
            emailCode: string
        }
        signUpProfileForm: {
            [key: string]: any
        }
        authSessionsList: AuthItem[]
        currentAuthSession?: AuthItem
    }
    gqlSimulator: {
        version: string
        env: "dev" | "production"
        selectedEndpoint: string
        endpointsData: {
            [key: string]: EndpointData
        }
        visibleQueryEndpoints?: VisibleQueryEndpoint[]
        visibleMutationEndpoints?: VisibleMutationEndpoint[]
        visibleInvitationUserGroups?: string[]
        invitationEndpoint?: {
            userGroup?: string
            email?: string
            password?: string
            invitationCode?: string
        }
        acceptInvitationEndpoint?: {
            email?: string
            invitationCode?: string
            password?: string
            userGroup?: string
        }
    }

    debug: boolean
    sessionId: string
}

export type { AuthItem, EndpointFilter, EndpointData, VisibleQueryEndpoint, VisibleMutationEndpoint, SimulatorModelContext }

const SimulatorModel = createModel(
    // Initial context
    {
        notificationsApi: undefined as undefined | NotificationInstance,

        projectId: "",
        projectConfig: {} as Config,
        generatedEndpoints: {} as Endpoints,
        clientTypes: "",
        queries: "",
        mutations: "",

        apiAuth: {
            newAuthType: "APIKEY",
            userGroup: "",
            loginForm: {
                username: "",
                password: "",
            },
            signupForm: {
                username: "",
                password: "",
                invitationCode: "",
                emailCode: "",
            },
            signUpProfileForm: {},
            authSessionsList: [],
        },
        gqlSimulator: {
            version: "",
            env: "dev",
            selectedEndpoint: "",
            endpointsData: {},
        },

        debug: false,
        sessionId: "",
    } as SimulatorModelContext,
    {
        // Event creators
        events: {
            UPDATE_NOTIFICATIONS_API: (notificationsApi: NotificationInstance) => ({ notificationsApi }),
            FETCH: (projectId: string, sessionId: string) => ({ projectId, sessionId }),
            FETCHED: (projectConfig: Config, generatedEndpoints: Endpoints, clientTypes: string, queries: string, mutations: string) => ({
                projectConfig,
                generatedEndpoints,
                clientTypes,
                queries,
                mutations,
            }),
            ERROR: (err: string) => ({ err }),
            TRY_AGAIN: () => ({}),
            SIMULATOR: () => ({}),
            DOCS: () => ({}),
            API_USERS: () => ({}),
            LOGIN_SESSIONS: () => ({}),
            CHECK_ALL_RUNABILITY: () => ({}),
            // Docs
            TYPESCRIPT_TYPES: () => ({}),
            GRAPHQL_QUERIES: () => ({}),
            GRAPHQL_MUTATIONS: () => ({}),
            // // Auth
            TO_SELF_SINGUP: () => ({}),
            TO_LOGIN_SESSION: () => ({}),
            TO_EMAIL_INVITATION: () => ({}),
            GO_CREDENTIAL: () => ({}),
            GO_PROFILE: () => ({}),
            FINISH_PROFILE: () => ({}),
            GO_CONFIRMATION: () => ({}),
            GO_SESSIONS: () => ({}),
            GO_LOGIN: () => ({}),
            GO_SIGNUP: () => ({}),
            GO_EMAIL_ADDRESS: () => ({}),
            GO_SIMULATOR: () => ({}),
            SESSION_LOGOUT: () => ({}),
            SESSION_DELETE: (sessionIndex: number) => ({ sessionIndex }),
            SELECT_SESSION: (sessionIndex: number) => ({ sessionIndex }),
            AUTH_TOKEN_REFRESHED: (authSessionsList: AuthItem[], currentAuthSession: AuthItem) => ({ authSessionsList, currentAuthSession }),
            SELECT_SIMULATOR_ENV: (env: "dev" | "production") => ({ env }),
            NEW_SESSION: () => ({}),
            DELETE_SESSION: (sessionIndex: number) => ({ sessionIndex }),
            SELECT_API: (apikey: string) => ({ apikey }),
            SELECT_USER: (userGroup: string) => ({ userGroup }),
            BACK_TO_SESSIONS: () => ({}),
            BACK_TO_SELECTED_SESSION: () => ({}),
            BACK_TO_INVITATION_EMAIL: () => ({}),
            SET_USERNAME: (username: string) => ({ username }),
            SET_PASSWORD: (password: string) => ({ password }),
            SET_INVITATION_TOKEN: (invitationCode: string) => ({ invitationCode }),
            LOGIN_FORM: () => ({}),
            SIGNUP_FORM: () => ({}),
            SENDER_LOGIN_CALL: () => ({}),
            //
            LOGIN_CALL: () => ({}),
            LOGED_IN: (authToken: string, refreshToken: string) => ({ authToken, refreshToken }),
            //
            SIGNUP_PROFILE: () => ({}),
            SET_PROFILE: (values: any) => ({ values }),
            FINISH_SIGNUP: () => ({}),
            SIGNED_UP: () => ({}),
            SIGNED_UP_WITH_INVITATION: (authToken: string, refreshToken: string) => ({ authToken, refreshToken }),
            CONFIRM_EMAIL: () => ({}),
            VERIFY_EMAIL: () => ({}),
            RESEND_EMAIL: () => ({}),
            SET_CODE: (code: string) => ({ code }),
            CONFIRMED: (authToken: string, refreshToken: string) => ({ authToken, refreshToken }),
            //
            LOGOUT: () => ({}),
            // // GQL Simulator
            SELECT_ENDPOINT: (endpointName: string) => ({ endpointName }),
            CLOSE_ENDPOINT: () => ({}),
            COLLAPSE_ALL: () => ({}),
            CLOSE: () => ({}),
            SET_OBJECTID: (endpointName: string, objectId: string) => ({ endpointName, objectId }),
            // Invitation
            SET_INVITATION_EMAIL: (email: string) => ({ email }),
            SEND_INVITATION: () => ({}),
            INVITED: () => ({}),
            SET_ACCEPT_INVITATION_EMAIL: (email: string) => ({ email }),
            SET_ACCEPT_INVITATION_CODE: (invitationCode: string) => ({ invitationCode }),
            SET_ACCEPT_INVITATION_PASSWORD: (password: string) => ({ password }),
            ACCEPT_INVITATION: () => ({}),
            ACCEPTED_INVITATION: (authToken: string, refreshToken: string) => ({ authToken, refreshToken }), // TODO: not sure if we need to implement this here
            // GQL Simulator filters
            OPEN_FILTER: (endpointName: string) => ({ endpointName }),
            ADD_RULE: () => ({}),
            CHANGE_RULE_FIELD: (ruleIndex: number, field: string) => ({ ruleIndex, field }),
            REMOVE_RULE: (ruleIndex: number) => ({ ruleIndex }),
            CHANGE_OPERATOR: (ruleIndex: number, operator: string) => ({ ruleIndex, operator }),
            CHANGE_VALUE: (ruleIndex: number, value: string) => ({ ruleIndex, value }),
            // GQL Simulator selector
            OPEN_SELECTOR_FIELDS: (endpointName: string) => ({ endpointName }),
            SELECT_FIELD: (field: string) => ({ field }),
            UNSELECT_FIELD: (field: string) => ({ field }),
            // GQL Simulator inputs
            OPEN_MUTATION_FORM: (endpointName: string) => ({ endpointName }),
            SELECT_SUB_OBJECT: (field: string) => ({ field }),
            UNSELECT_SUB_OBJECT: (field: string) => ({ field }),
            SET_MUTATION_FORM_FIELDS_VALUES: (values: any) => ({ values }),
            // GQL Simulator Response
            OPEN_CODE_SAMPLE: (endpointName: string) => ({ endpointName }),
            // GQL Simulator Response
            RUN: (endpointName: string) => ({ endpointName }),
            COMPLETED: (endpointName: string, endTime: Date, raw?: any) => ({ raw, endpointName, endTime }),
            SHOW_RESPONSE: (endpoint: string) => ({ endpoint }),
            HIDE: () => ({}),
            RAW: () => ({}),
            TABLE: () => ({}),
            TREE: () => ({}),
        },
    }
)

export { SimulatorModel }
