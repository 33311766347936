import { useState, useEffect } from "react"
import {
    ApartmentOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    PlusCircleFilled,
    DatabaseOutlined,
    ReloadOutlined,
    RollbackOutlined,
    UserOutlined,
    CheckCircleFilled,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    CheckOutlined,
} from "@ant-design/icons"
import { getSingular } from "mypluralize"
import { Input, Dropdown, Button, Badge, Divider, Tooltip, Form, Popconfirm } from "antd"

import type { UserFormType } from "../../../machines/DesignerModel"
import type { Config, EntityField, AccessItem } from "../../../coreTypes/config"
import { Colors, Spaces, StyleHelpers } from "../../global"
import DataField from "../DataField"
import FieldForm, { FieldFormProps } from "../FieldForm"
import {
    FormWrapper,
    FormHeader,
    FormBasicInfo,
    FormBody,
    MoreWrapper,
    BadgesArray,
    BadgeWithText,
    BadgeText,
    DividerWithButton,
    DivideWrapper,
    SecondaryButton,
    ButtonGroupWrapper,
    FormHeaderBody,
} from "../DataEntitiesTab/EntitiesFormWithFields"

import { SwitchersGroup, Switcher } from "../../Switcher"
import { cleanInputNameStartingUpper } from "../../../helpers/functions"
import { NotificationInstance } from "antd/es/notification/interface"

const { TextArea } = Input

export type UserFormProps = {
    userForm: UserFormType
    changeGroupName: (groupName: string) => void
    changeDescription: (descriptions: string) => void
    sendSaveUser: () => void
    sendResetUser: () => void
    sendDeleteUser: () => void
    sendCancelUser: () => void
    switchTrackChanges: () => void
    sendObservePermissions: (accessItems: AccessItem[]) => void
    isObservePermissions: boolean
    notificationsApi?: NotificationInstance
}

type UserFormPropsExtended = UserFormProps & {
    projectConfig: Config
    sendFieldForm: (field?: EntityField) => void
    isFieldForm: boolean
    isSavingForm: boolean
    fieldFormProps: FieldFormProps
    isPermissionForm: boolean
}

export default function UserGroupFormWithFields(props: UserFormPropsExtended) {
    const {
        projectConfig,
        userForm,
        changeGroupName,
        changeDescription,
        sendSaveUser,
        sendResetUser,
        sendDeleteUser,
        sendCancelUser,
        switchTrackChanges,
        sendFieldForm,
        isFieldForm,
        isSavingForm,
        fieldFormProps,
        sendObservePermissions,
        isObservePermissions,
        isPermissionForm,
        notificationsApi,
    } = props
    const { user, originalUser } = userForm
    const [isHovered, setIsHovered] = useState(false)
    const [userGroupNameErrMessage, setUserGroupNameErrMessage] = useState("")
    const [userGroupTitle, setUserGroupTitle] = useState(user.groupName)

    useEffect(() => {
        if (projectConfig.users.map((user) => user.groupName).includes(cleanInputNameStartingUpper(user.groupName))) {
            setUserGroupNameErrMessage("The same name exists.")
        } else {
            setUserGroupNameErrMessage("")
        }
        setUserGroupTitle(user.groupName)
    }, [user.groupName])

    const userConnectionsQty = user.userData.fields?.reduce((acc, field) => {
        return acc + (field.connectedEntityName ? 1 : 0)
    }, 0)

    const menuItems: any = []
    if (originalUser)
        menuItems.push({
            key: "delete",
            label: (
                <Popconfirm title="Are you sure you want to delete?" onConfirm={sendDeleteUser} okText="Yes" cancelText="No">
                    delete
                </Popconfirm>
            ),
            icon: <DeleteOutlined />,
        })

    if (originalUser && JSON.stringify(originalUser) !== JSON.stringify(user)) {
        menuItems.push({
            key: "reset",
            label: (
                <Popconfirm title="Are you sure you want to reset without saving changes?" onConfirm={sendResetUser} okText="Yes" cancelText="No">
                    reset
                </Popconfirm>
            ),
            icon: <ReloadOutlined />,
        })
    }

    const menuItemAction = (menuItem: any) => {
        switch (menuItem.key) {
            case "delete":
                sendDeleteUser()
                break
            case "reset":
                sendResetUser()
                break
        }
    }

    let btnTooltipContent = ""

    switch (true) {
        case isFieldForm:
            btnTooltipContent = "Save or Close selected field form"
            break
        case !user.groupName:
            btnTooltipContent = "Fill in user group name"
            break
        case user.userData.fields.length === 0:
            btnTooltipContent = "Add at least one custom field"
            break
        case isPermissionForm:
            btnTooltipContent = "Close access permissions"
            break
        default:
            break
    }

    const isSavable = !originalUser || (originalUser && JSON.stringify(originalUser) !== JSON.stringify(user))

    const nameEndsTest = (name: string) => {
        if (getSingular(name) !== name) {
            return true
        }
        return name.endsWith("id") || name.endsWith("Id") || name.endsWith("ID") || name.endsWith("iD")
    }
    return (
        <FormWrapper isHovered={isHovered} style={{ position: "relative" }}>
            {/* Not required to cover the whole screen here because we have loading state of the form submit sutton indicating loading */}
            {/* {isSavingForm && (
                <PageWrapper
                    style={{
                        zIndex: 2,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: Colors.transparentBackground,
                        backdropFilter: StyleHelpers.blur,
                    }}
                >
                    <Centered vertical>
                        <LoadingOutlined style={{ fontSize: "65px", color: Colors.primary }} />
                        <Title level={3}>Saving user group changes...</Title>
                    </Centered>
                </PageWrapper>
            )} */}

            <FormHeader>
                <FormHeaderBody>
                    <UserOutlined style={{ fontSize: "30px", color: Colors.grayDark, marginTop: "5px" }} />
                    <Form.Item
                        style={{ margin: "0 auto", width: "300px", marginLeft: `${Spaces.normal}` }}
                        validateStatus={!originalUser && userGroupNameErrMessage.length > 0 ? "error" : ""}
                        help={!originalUser && userGroupNameErrMessage}
                    >
                        <Input
                            // disabled FIXME: disable if there are objects in DB and show tooltip
                            placeholder="User group name (singular noun)"
                            title="User group name"
                            disabled={isFieldForm || isPermissionForm || isSavingForm}
                            size="large"
                            value={userGroupTitle}
                            autoFocus={!user.groupName}
                            onBlur={(e) => {
                                changeGroupName(e.target.value)
                                setUserGroupTitle(cleanInputNameStartingUpper(e.target.value))
                                if (nameEndsTest(userGroupTitle)) {
                                    notificationsApi?.error({
                                        message: "Error",
                                        description: "User group names cannot be plural or end with 'id'. Please choose a different name.",
                                        placement: "bottomLeft",
                                        duration: 7,
                                    })
                                }
                            }}
                            onChange={(e) => setUserGroupTitle(e.target.value)}
                        />
                    </Form.Item>

                    <ButtonGroupWrapper>
                        {menuItems.length > 1 && (
                            <MoreWrapper>
                                <Dropdown
                                    disabled={isFieldForm || isPermissionForm || isSavingForm}
                                    menu={{
                                        items: menuItems,
                                    }}
                                    placement="bottomRight"
                                >
                                    <EllipsisOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                                </Dropdown>
                            </MoreWrapper>
                        )}

                        {menuItems.length === 1 && !isSavable && (
                            <MoreWrapper>
                                <Popconfirm
                                    title="Are you sure you want to perform this action?"
                                    onConfirm={() => {
                                        menuItemAction(menuItems[0])
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={isSavingForm} type="text" shape="circle" icon={menuItems[0].icon} />
                                </Popconfirm>
                            </MoreWrapper>
                        )}

                        {isSavable && (
                            <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                                <Popconfirm
                                    title="Are you sure you want to cancel without saving changes?"
                                    onConfirm={sendCancelUser}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={isSavingForm} type="text" shape="circle" icon={<RollbackOutlined />} />
                                </Popconfirm>
                            </MoreWrapper>
                        )}

                        <Tooltip title={btnTooltipContent}>
                            <SecondaryButton
                                isSolid={isSavable}
                                inactive={isFieldForm || isPermissionForm}
                                disabled={
                                    isFieldForm ||
                                    !user.groupName ||
                                    user.userData.fields.length === 0 ||
                                    isPermissionForm ||
                                    (!originalUser && userGroupNameErrMessage.length > 0) ||
                                    nameEndsTest(userGroupTitle)
                                }
                                loading={isSavingForm}
                                type="primary"
                                shape="round"
                                icon={isSavable ? <CheckOutlined /> : <RollbackOutlined />}
                                onClick={isSavable ? sendSaveUser : sendCancelUser}
                            >
                                {isSavable ? "Save" : "Back"}
                            </SecondaryButton>
                        </Tooltip>
                    </ButtonGroupWrapper>
                </FormHeaderBody>
            </FormHeader>

            <FormBody>
                <FormBasicInfo>
                    <BadgesArray>
                        <BadgeWithText>
                            <Badge
                                showZero
                                count={userConnectionsQty}
                                style={{
                                    marginLeft: Spaces.small,
                                    backgroundColor: Colors.grayLight,
                                    borderColor: Colors.grayLight,
                                    color: Colors.grayDark,
                                }}
                            />
                            <ApartmentOutlined style={{ fontSize: "18px", color: Colors.grayDark }} />
                            <BadgeText>fields with connected entities</BadgeText>
                        </BadgeWithText>
                        <BadgeWithText>
                            <Badge
                                showZero
                                count={0} // FIXME: fetch from backend
                                style={{
                                    marginLeft: Spaces.small,
                                    backgroundColor: Colors.grayLight,
                                    borderColor: Colors.grayLight,
                                    color: Colors.grayDark,
                                }}
                            />
                            <DatabaseOutlined style={{ fontSize: "18px", color: Colors.grayDark }} />
                            <BadgeText>objects in database</BadgeText>
                        </BadgeWithText>
                    </BadgesArray>

                    <TextArea
                        disabled={isFieldForm || isPermissionForm || isSavingForm}
                        placeholder="(optional) Corresponding description of the user group..."
                        title="Corresponding description of the user group..."
                        value={user.description}
                        onChange={(e) => {
                            changeDescription(e.target.value)
                        }}
                        autoSize={{ minRows: 2, maxRows: 5 }}
                    />

                    <SwitchersGroup>
                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={
                                user.userData.trackChanges
                                    ? "(enabled) Saving the history of the changes of all the objects of this user group in the database. This allows admins to access history later."
                                    : "Enable to save the history of the changes of all the objects of this user group in the database. This allows admins to access history later."
                            }
                        >
                            <Switcher
                                selected={user.userData.trackChanges}
                                inactive={isFieldForm || isPermissionForm || isSavingForm}
                                onClick={() => !isFieldForm && !isPermissionForm && switchTrackChanges()}
                            >
                                {user.userData.trackChanges && (
                                    <CheckCircleFilled
                                        style={{
                                            fontSize: StyleHelpers.iconSize,
                                            color: isFieldForm || isPermissionForm ? Colors.grayNormal : Colors.background,
                                        }}
                                    />
                                )}
                                {user.userData.trackChanges ? "is tracking changes" : "Track changes"}
                            </Switcher>
                        </Tooltip>
                    </SwitchersGroup>
                </FormBasicInfo>

                <DividerWithButton>
                    <DivideWrapper>
                        <Divider>{`Data fields (${Object.keys(user.userData.defaultFields).length + user.userData.fields.length})`}</Divider>
                    </DivideWrapper>
                    {user.userData.fields.length > 0 && (
                        <Tooltip
                            title={
                                userForm.originalUser === undefined
                                    ? "Save the current user group to be able to set access permissions."
                                    : "In case any user need to have access to this user group object through API."
                            }
                        >
                            <Button
                                type="primary"
                                size="small"
                                disabled={isPermissionForm || userForm.originalUser === undefined || isSavingForm}
                                icon={isObservePermissions ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                                onClick={() => {
                                    sendObservePermissions(user.userData.access)
                                }}
                            >
                                {isObservePermissions ? "Close access permissions" : "Set access permissions"}
                            </Button>
                        </Tooltip>
                    )}
                </DividerWithButton>

                {Object.keys(user.userData.defaultFields).map((name, index: number) => {
                    const field = {
                        name,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        type: user.userData.defaultFields[name],
                    } as EntityField

                    return (
                        <DataField
                            key={field.name}
                            index={index}
                            field={field}
                            isFieldForm={isFieldForm}
                            isPermissionForm={isPermissionForm}
                            isSavingForm={isSavingForm}
                            sendFieldForm={sendFieldForm}
                            isDefaultField
                        />
                    )
                })}

                {user.userData.fields.map((field, index: number) => {
                    if (isFieldForm && field.name === fieldFormProps.fieldForm.originalField?.name) {
                        return (
                            <FieldForm
                                isUserGroupForm
                                key={field.name}
                                projectConfig={projectConfig}
                                fields={user.userData.fields}
                                {...fieldFormProps}
                            />
                        )
                    } else {
                        return (
                            <DataField
                                key={field.name}
                                index={index}
                                field={field}
                                isFieldForm={isFieldForm}
                                isPermissionForm={isPermissionForm}
                                isSavingForm={isSavingForm}
                                sendFieldForm={sendFieldForm}
                            />
                        )
                    }
                })}

                {isFieldForm && !fieldFormProps.fieldForm.originalField && (
                    <FieldForm isUserGroupForm projectConfig={projectConfig} fields={user.userData.fields} {...fieldFormProps} />
                )}

                <Button
                    disabled={isFieldForm || isPermissionForm || isSavingForm}
                    icon={<PlusCircleFilled />}
                    style={{ margin: "0 auto" }}
                    type="primary"
                    onClick={() => {
                        sendFieldForm()
                    }}
                >
                    Add field
                </Button>
            </FormBody>
        </FormWrapper>
    )
}
