import { useState, useEffect } from "react"
import styled from "styled-components"
import {
    ApartmentOutlined,
    DeploymentUnitOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    PlusCircleFilled,
    DatabaseOutlined,
    ReloadOutlined,
    RollbackOutlined,
    CheckCircleFilled,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    CheckOutlined,
} from "@ant-design/icons"
import { getSingular } from "mypluralize"
import { Input, Dropdown, Button, Badge, Divider, Tooltip, Form, Popconfirm } from "antd"

import type { EntityFormType } from "../../../machines/DesignerModel"
import type { Config, EntityField, AccessItem } from "../../../coreTypes/config"
import { Colors, Spaces, StyleHelpers } from "../../global"
import { SwitchersGroup, Switcher } from "../../Switcher"
import DataField from "../DataField"
import FieldForm, { FieldFormProps } from "../FieldForm"
import { cleanInputNameStartingUpper } from "../../../helpers/functions"
import { NotificationInstance } from "antd/es/notification/interface"
const { TextArea } = Input

export type EntityFormProps = {
    entityForm: EntityFormType
    isSavingForm: boolean
    changeEntityName: (entityName: string) => void
    changeDescription: (descriptions: string) => void
    sendSaveEntity: () => void
    sendResetEntity: () => void
    sendDeleteEntity: () => void
    sendCancelEntity: () => void
    switchTrackChanges: () => void
    sendObservePermissions: (accessItems: AccessItem[]) => void
    isObservePermissions: boolean
    notificationsApi?: NotificationInstance
}

type EntityFormPropsExtended = EntityFormProps & {
    projectConfig: Config
    sendFieldForm: (field?: EntityField) => void
    isFieldForm: boolean
    fieldFormProps: FieldFormProps
    isPermissionForm: boolean
}

export default function EntityGroupFormWithFields(props: EntityFormPropsExtended) {
    const {
        projectConfig,
        entityForm,
        isSavingForm,
        changeEntityName,
        changeDescription,
        sendSaveEntity,
        sendResetEntity,
        sendDeleteEntity,
        sendCancelEntity,
        switchTrackChanges,
        sendFieldForm,
        isFieldForm,
        fieldFormProps,
        sendObservePermissions,
        isObservePermissions,
        isPermissionForm,
        notificationsApi,
    } = props
    const { entity } = entityForm
    const [isHovered, setIsHovered] = useState(false)

    const [entityNameValue, setEntityNameValue] = useState(entity.entityName)
    const [entityNameErrMessage, setEntityNameErrMessage] = useState("")

    useEffect(() => {
        if (projectConfig.entities.map((entity) => entity.entityName).includes(cleanInputNameStartingUpper(entity.entityName))) {
            setEntityNameErrMessage("The same entity name exists.")
        } else {
            setEntityNameErrMessage("")
        }
        setEntityNameValue(entity.entityName)
    }, [entity.entityName])

    const entityConnectionsQty = entity.fields?.reduce((acc, field) => {
        return acc + (field.connectedEntityName ? 1 : 0)
    }, 0)

    const nameEndsTest = (name: string) => {
        if (getSingular(name) !== name) {
            return true
        }
        return name.endsWith("id") || name.endsWith("Id") || name.endsWith("ID") || name.endsWith("iD")
    }

    const menuItems: any = []
    if (entityForm.originalEntity)
        menuItems.push({
            key: "delete",
            label: (
                <Popconfirm title="Are you sure you want to delete?" onConfirm={sendDeleteEntity} okText="Yes" cancelText="No">
                    delete
                </Popconfirm>
            ),
            icon: <DeleteOutlined />,
        })

    if (entityForm.originalEntity && JSON.stringify(entityForm.originalEntity) !== JSON.stringify(entity)) {
        menuItems.push({
            key: "reset",
            label: (
                <Popconfirm title="Are you sure you want to reset without saving changes?" onConfirm={sendResetEntity} okText="Yes" cancelText="No">
                    reset
                </Popconfirm>
            ),
            icon: <ReloadOutlined />,
        })
    }

    const menuItemAction = (menuItem: any) => {
        switch (menuItem.key) {
            case "delete":
                sendDeleteEntity()
                break
            case "reset":
                sendResetEntity()
                break
        }
    }

    let btnTooltipContent = ""

    switch (true) {
        case isFieldForm:
            btnTooltipContent = "Save or Close selected field form"
            break
        case !entity.entityName:
            btnTooltipContent = "Fill in entity name"
            break
        case entity.fields.length === 0:
            btnTooltipContent = "Add at least one custom field"
            break
        case isPermissionForm:
            btnTooltipContent = "Close access permissions"
            break
        default:
            break
    }

    const isSavable =
        !entityForm.originalEntity || (entityForm.originalEntity && JSON.stringify(entityForm.originalEntity) !== JSON.stringify(entity))

    return (
        <FormWrapper isHovered={isHovered} style={{ position: "relative" }}>
            {/* Not required to cover the whole screen here because we have loading state of the form submit sutton indicating loading */}
            {/* {isSavingForm && (
                <PageWrapper
                    style={{
                        zIndex: 2,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: Colors.transparentBackground,
                        backdropFilter: StyleHelpers.blur,
                    }}
                >
                    <Centered vertical>
                        <LoadingOutlined style={{ fontSize: "65px", color: Colors.primary }} />
                        <Title level={3}>Saving data entity changes...</Title>
                    </Centered>
                </PageWrapper>
            )} */}

            <FormHeader>
                <FormHeaderBody>
                    <DeploymentUnitOutlined style={{ fontSize: "30px", color: Colors.grayDark }} />
                    <Form.Item
                        style={{ margin: "0 auto", width: "300px", marginLeft: `${Spaces.normal}` }}
                        validateStatus={!entityForm.originalEntity && entityNameErrMessage.length > 0 ? "error" : ""}
                        help={!entityForm.originalEntity && entityNameErrMessage}
                    >
                        <Input
                            // disabled FIXME: disable if there are objects in DB and show tooltip
                            placeholder="Entity name (singular noun)"
                            title="Entity name"
                            disabled={isFieldForm || isPermissionForm || isSavingForm}
                            size="large"
                            value={entityNameValue}
                            autoFocus={!entity.entityName}
                            onBlur={(e) => {
                                changeEntityName(e.target.value)
                                setEntityNameValue(cleanInputNameStartingUpper(e.target.value))
                                if (nameEndsTest(entityNameValue)) {
                                    notificationsApi?.error({
                                        message: "Error",
                                        description: "Entity names cannot be plural or end with 'id'. Please choose a different name.",
                                        placement: "bottomLeft",
                                        duration: 7,
                                    })
                                }
                            }}
                            onChange={(e) => {
                                setEntityNameValue(e.target.value)
                            }}
                        />
                    </Form.Item>

                    <ButtonGroupWrapper>
                        {menuItems.length > 1 && (
                            <MoreWrapper>
                                <Dropdown
                                    disabled={isFieldForm || isPermissionForm || isSavingForm}
                                    menu={{
                                        items: menuItems,
                                    }}
                                    placement="bottomRight"
                                >
                                    <EllipsisOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                                </Dropdown>
                            </MoreWrapper>
                        )}
                        {menuItems.length == 1 && !isSavable && (
                            <MoreWrapper>
                                <Popconfirm
                                    title="Are you sure you want to perform this action?"
                                    onConfirm={() => {
                                        menuItemAction(menuItems[0])
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={isSavingForm} type="text" shape="circle" icon={menuItems[0].icon} />
                                </Popconfirm>
                            </MoreWrapper>
                        )}

                        {isSavable && (
                            <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                                <Popconfirm
                                    title="Are you sure you want to cancel without saving changes?"
                                    onConfirm={sendCancelEntity}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={isSavingForm} type="text" shape="circle" icon={<RollbackOutlined />} />
                                </Popconfirm>
                            </MoreWrapper>
                        )}

                        <Tooltip title={btnTooltipContent}>
                            <SecondaryButton
                                isSolid={isSavable}
                                inactive={isFieldForm || isPermissionForm}
                                disabled={
                                    isFieldForm ||
                                    !entity.entityName ||
                                    entity.fields.length === 0 ||
                                    isPermissionForm ||
                                    (!entityForm.originalEntity && entityNameErrMessage.length > 0) ||
                                    nameEndsTest(entityNameValue)
                                }
                                loading={isSavingForm}
                                type="primary"
                                shape="round"
                                icon={
                                    !entityForm.originalEntity ||
                                    (entityForm.originalEntity && JSON.stringify(entityForm.originalEntity) !== JSON.stringify(entity)) ? (
                                        <CheckOutlined />
                                    ) : (
                                        <RollbackOutlined />
                                    )
                                }
                                onClick={
                                    !entityForm.originalEntity ||
                                    (entityForm.originalEntity && JSON.stringify(entityForm.originalEntity) !== JSON.stringify(entity))
                                        ? sendSaveEntity
                                        : sendCancelEntity
                                }
                            >
                                {isSavable ? "Save" : "Back"}
                            </SecondaryButton>
                        </Tooltip>
                    </ButtonGroupWrapper>
                </FormHeaderBody>
            </FormHeader>

            <FormBody>
                <FormBasicInfo>
                    <BadgesArray>
                        <BadgeWithText>
                            <Badge
                                showZero
                                count={entityConnectionsQty}
                                style={{
                                    marginLeft: Spaces.small,
                                    backgroundColor: Colors.grayLight,
                                    borderColor: Colors.grayLight,
                                    color: Colors.grayDark,
                                }}
                            />
                            <ApartmentOutlined style={{ fontSize: "18px", color: Colors.grayDark }} />
                            <BadgeText>fields with connected entities</BadgeText>
                        </BadgeWithText>
                        <BadgeWithText>
                            <Badge
                                showZero
                                count={0} // FIXME: fetch from backend quantity of objects in DB [Tasks]
                                style={{
                                    marginLeft: Spaces.small,
                                    backgroundColor: Colors.grayLight,
                                    borderColor: Colors.grayLight,
                                    color: Colors.grayDark,
                                }}
                            />
                            <DatabaseOutlined style={{ fontSize: "18px", color: Colors.grayDark }} />
                            <BadgeText>objects in database</BadgeText>
                        </BadgeWithText>
                    </BadgesArray>

                    <TextArea
                        disabled={isFieldForm || isPermissionForm || isSavingForm}
                        placeholder="(optional) Corresponding description of the data entity..."
                        title="Corresponding description of the data entity..."
                        value={entity.description}
                        onChange={(e) => {
                            changeDescription(e.target.value)
                        }}
                        autoSize={{ minRows: 2, maxRows: 5 }}
                    />

                    <SwitchersGroup>
                        <Tooltip
                            mouseEnterDelay={0.4}
                            title={
                                entity.trackChanges
                                    ? "(enabled) Saving the history of the changes of all the objects of this data entity in the database. This allows admins to access history later."
                                    : "Enable to save the history of the changes of all the objects of this data entity in the database. This allows admins to access history later."
                            }
                        >
                            <Switcher
                                selected={entity.trackChanges}
                                inactive={isFieldForm || isPermissionForm || isSavingForm}
                                onClick={() => !isFieldForm && !isPermissionForm && switchTrackChanges()}
                            >
                                {
                                    entity.trackChanges && (
                                        <CheckCircleFilled
                                            style={{
                                                fontSize: StyleHelpers.iconSize,
                                                color: isFieldForm || isPermissionForm ? Colors.grayNormal : Colors.background,
                                            }}
                                        />
                                    )
                                    // : (
                                    //     <CloseCircleOutlined
                                    //         style={{
                                    //             fontSize: StyleHelpers.iconSize,
                                    //             color: isFieldForm || isPermissionForm ? Colors.grayNormal : Colors.primary,
                                    //         }}
                                    //     />
                                    // )
                                }
                                {entity.trackChanges ? "is tracking changes" : "Track changes"}
                            </Switcher>
                        </Tooltip>
                    </SwitchersGroup>
                </FormBasicInfo>

                <DividerWithButton>
                    <DivideWrapper>
                        <Divider>{`Data fields (${Object.keys(entity.defaultFields).length + entity.fields.length})`}</Divider>
                    </DivideWrapper>
                    {entity.fields.length > 0 && (
                        <Tooltip
                            title={
                                entityForm.originalEntity === undefined
                                    ? "First save the current data entity to be able to set access permissions."
                                    : "In case any user need to have access to this data entity object through API."
                            }
                        >
                            <Button
                                type="primary"
                                size="small"
                                disabled={isPermissionForm || entityForm.originalEntity === undefined || isSavingForm}
                                icon={isObservePermissions ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                                onClick={() => {
                                    sendObservePermissions(entity.access)
                                }}
                            >
                                {isObservePermissions ? "Close access permissions" : "Set access permissions"}
                            </Button>
                        </Tooltip>
                    )}
                </DividerWithButton>

                {Object.keys(entity.defaultFields).map((name, index: number) => {
                    const field = {
                        name,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        type: entity.defaultFields[name],
                    } as EntityField

                    return (
                        <DataField
                            key={field.name}
                            index={index}
                            field={field}
                            isFieldForm={isFieldForm}
                            isPermissionForm={isPermissionForm}
                            isSavingForm={isSavingForm}
                            sendFieldForm={sendFieldForm}
                            isDefaultField
                        />
                    )
                })}

                {entity.fields.map((field, index: number) => {
                    if (isFieldForm && field.name === fieldFormProps.fieldForm.originalField?.name) {
                        return <FieldForm key={field.name} projectConfig={projectConfig} fields={entity.fields} {...fieldFormProps} />
                    } else {
                        return (
                            <DataField
                                key={field.name}
                                index={index}
                                field={field}
                                isFieldForm={isFieldForm}
                                isPermissionForm={isPermissionForm}
                                isSavingForm={isSavingForm}
                                sendFieldForm={sendFieldForm}
                            />
                        )
                    }
                })}

                {isFieldForm && !fieldFormProps.fieldForm.originalField && (
                    <FieldForm projectConfig={projectConfig} fields={entity.fields} {...fieldFormProps} />
                )}

                <Button
                    disabled={isFieldForm || isPermissionForm || isSavingForm}
                    icon={<PlusCircleFilled />}
                    style={{ margin: "0 auto" }}
                    type="primary"
                    onClick={() => {
                        sendFieldForm()
                    }}
                >
                    Add field
                </Button>
            </FormBody>
        </FormWrapper>
    )
}

const FormWrapper = styled.div<{ isHovered: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: ${(props: { isHovered: boolean }) => (props.isHovered ? Colors.grayLight : "unset")}; */
    width: 100%;
`

const FormHeader = styled.div`
    width: 100%;
    box-shadow: ${StyleHelpers.boldBoxShadow};
    z-index: 1;
`

const FormHeaderBody = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: start;
    padding: ${Spaces.normal};
    justify-content: space-between;

    margin: 0 auto;
    width: calc(100% - 20px);
    max-width: 800px;
    input {
        max-width: 300px;
    }
`

const FormBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${Spaces.xLarge} ${Spaces.small} 80px;
    gap: ${Spaces.medium};

    margin: 0 auto;
    width: calc(100% - 20px);
    max-width: 800px;

    textarea {
        max-width: 500px;
    }

    height: calc(100vh - 110px);
    overflow: scroll;
    scrollbar-width: none;
`

const FormBasicInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 ${Spaces.normal};
    gap: ${Spaces.medium};

    width: 100%;
    padding-left: 50px;
`

const MoreWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 ${Spaces.normal};
`

const BadgesArray = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: ${Spaces.large};
`

const BadgeWithText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: ${Spaces.small};
`

const BadgeText = styled.div``

const DividerWithButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
`

const DivideWrapper = styled.div`
    width: calc(100% - 210px);

    &:last-child {
        width: 100%;
    }
`

const SecondaryButton = styled(Button)<{ isSolid: boolean; inactive?: boolean }>`
    color: ${(props: { isSolid: boolean }) => !props.isSolid && Colors.primary} ${(props: { inactive?: boolean }) => !props.inactive && "!important"};
    border: ${(props: { isSolid: boolean }) => !props.isSolid && `1px solid ${Colors.primary}`};
    background-color: ${(props: { isSolid: boolean; inactive?: boolean }) => !props.isSolid && !props.inactive && Colors.background};
    font-weight: normal;
    ${(props: { inactive?: boolean; isSolid: boolean }) =>
        !props.inactive &&
        `
        &: hover {
            background-color: ${!props.isSolid && Colors.grayLight} !important;
        }
    `}
`

const ButtonGroupWrapper = styled.div`
    display: flex;
    margin-top: 5px;
`

export {
    SecondaryButton,
    ButtonGroupWrapper,
    FormWrapper,
    FormHeader,
    FormBasicInfo,
    FormBody,
    MoreWrapper,
    BadgesArray,
    BadgeWithText,
    BadgeText,
    DividerWithButton,
    DivideWrapper,
    FormHeaderBody,
}
