import { FC } from "react"
import { Form, Steps, Button, Select, Typography, Input, Space, Tooltip } from "antd"
import { Colors, Spaces, StyleHelpers } from "../../global"
import { MailOutlined, UserOutlined } from "@ant-design/icons"
import { OptionItemWrapper } from "../../Designer/FieldForm"
import { NotificationInstance } from "antd/es/notification/interface"
import styled from "styled-components"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"
import { MutationForm } from "../ApiSimulatorTab/SignUpProfile"
import { MutationFormWrapperCollapsible } from "../ApiSimulatorTab/EndpointsComponents"

const { OptGroup, Option } = Select
const { Text } = Typography

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
    notificationsApi: NotificationInstance
}

export const SignupForm: FC<Props> = ({ current, send, notificationsApi }: Props) => {
    const [form] = Form.useForm()

    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: notificationsApi })
    }
    const usergroupFields = current.context.projectConfig?.users.find((u: any) => u?.groupName === current.context.apiAuth?.userGroup)?.userData
        .fields

    const steps = [
        {
            title: <Tooltip title="1st step: sign up with the email (end-user of your application) and password that you want.">Credentials</Tooltip>,
            content: (
                <FormWrapper
                    key="credential"
                    onFinish={() => {
                        send("GO_PROFILE")
                    }}
                >
                    <Form.Item
                        name="usergroup"
                        rules={[{ required: true, message: "Please select usergroup!" }]}
                        style={{ marginBottom: Spaces.xLarge }}
                    >
                        <Select
                            placeholder="Select user group name..."
                            title="Select user group name..."
                            style={{ width: 225 }}
                            allowClear
                            value={current.context.apiAuth.userGroup === "" ? undefined : current.context.apiAuth.userGroup}
                            onSelect={(value) => {
                                send({
                                    type: "SELECT_USER",
                                    userGroup: value,
                                })
                            }}
                            size="large"
                        >
                            {current.context.projectConfig.users && (
                                <OptGroup label="User group" key="user group">
                                    {Object.keys(current.context.projectConfig.users.filter((user) => user.signupable)).map((un: string) => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        const u = current.context.projectConfig.users[un]
                                        return (
                                            <Option value={u.groupName} key={u.groupName}>
                                                <OptionItemWrapper>
                                                    <UserOutlined
                                                        style={{
                                                            width: StyleHelpers.iconSize,
                                                            color: Colors.grayDark,
                                                        }}
                                                    />
                                                    {u.groupName}
                                                </OptionItemWrapper>
                                            </Option>
                                        )
                                    })}
                                </OptGroup>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: "Please input email address of your application's end-user!" }]}
                        style={{ marginBottom: Spaces.normal }}
                    >
                        <Input
                            placeholder="User email address"
                            title="User email address"
                            size="large"
                            value={current.context.apiAuth.signupForm.username || ""}
                            onChange={(e) => {
                                send({ type: "SET_USERNAME", username: e.target.value })
                            }}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).+$/,
                                message: (
                                    <>
                                        Should contain at least 1 uppercase,
                                        <br />1 lowercase, 1 digit and 1 special charecter.
                                    </>
                                ),
                            },
                            {
                                min: 8,
                                message: "Should be at least 8 characters.",
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder="User password"
                            title="User password"
                            size="large"
                            value={current.context.apiAuth.signupForm.password || ""}
                            onChange={(e) => {
                                send({ type: "SET_PASSWORD", password: e.target.value })
                            }}
                            autoComplete="new-password"
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" size="large" style={{ width: "120px", margin: "0 auto", marginRight: "0" }}>
                        Sign Up
                    </Button>
                </FormWrapper>
            ),
            isActive: current.matches("default.tabs.apiUsers.selfSignup.credential"),
        },
        {
            title: <Tooltip title="2nd step: fulfill profile details.">Profile</Tooltip>,
            content: (
                <FormWrapper key="profile" form={form}>
                    <MutationFormWrapperCollapsible>
                        <MutationForm
                            form={form}
                            setMutationFormValues={(values: any) => {
                                send({ type: "SET_PROFILE", values: values })
                            }}
                            fields={usergroupFields}
                            projectConfig={current.context.projectConfig}
                        />
                    </MutationFormWrapperCollapsible>
                    <div style={{ margin: "0 auto", marginRight: "0" }}>
                        <Button
                            disabled={current.matches("default.tabs.apiUsers.selfSignup.profile.creatingUser")}
                            size="large"
                            style={{ width: "120px", marginRight: Spaces.normal }}
                            onClick={() => {
                                send("GO_CREDENTIAL")
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={current.matches("default.tabs.apiUsers.selfSignup.profile.creatingUser")}
                            size="large"
                            style={{ width: "120px" }}
                            onClick={async () => {
                                try {
                                    await form.validateFields()
                                    form.submit()
                                    send("FINISH_SIGNUP")
                                } catch (error) {
                                    console.error("Validation failed:", error)
                                }
                            }}
                        >
                            Finish
                        </Button>
                    </div>
                </FormWrapper>
            ),
            isActive: ["default.tabs.apiUsers.selfSignup.profile.profileForm", "default.tabs.apiUsers.selfSignup.profile.creatingUser"].some(
                current.matches
            ),
        },
        {
            title: <Tooltip title="3rd step: confirm the email address.">Confirmation</Tooltip>,
            content: (
                <FormWrapper
                    key="confirmation"
                    onFinish={() => {
                        send("CONFIRM_EMAIL")
                    }}
                >
                    <div style={{ marginBottom: Spaces.normal }}>
                        <MailOutlined /> &nbsp;
                        <Text type="secondary">
                            We sent a confirmation code to <b>{current.context.apiAuth.signupForm.username}</b> email address
                        </Text>
                    </div>
                    <Form.Item name="verificationCode" rules={[{ required: true, message: "Please input code!" }]} labelAlign="left">
                        <Space.Compact style={{ width: "100%" }}>
                            <Input
                                autoFocus
                                placeholder="Confirmation code"
                                onChange={(e) => {
                                    send({ type: "SET_CODE", code: e.target.value })
                                }}
                            />
                            <Button
                                disabled={current.matches("default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail")}
                                loading={current.matches("default.tabs.apiUsers.selfSignup.resendingEmail")}
                                type="primary"
                                onClick={() => {
                                    send("RESEND_EMAIL")
                                }}
                            >
                                Resend
                            </Button>
                        </Space.Compact>
                    </Form.Item>
                    <div style={{ margin: "0 auto", marginRight: "0" }}>
                        <Button
                            disabled={current.matches("default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail")}
                            size="large"
                            style={{ width: 120, marginRight: Spaces.normal }}
                            onClick={() => {
                                send("GO_PROFILE")
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            loading={current.matches("default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail")}
                            htmlType="submit"
                            type="primary"
                            size="large"
                            style={{ width: 120 }}
                        >
                            Confirm
                        </Button>
                    </div>
                </FormWrapper>
            ),
            isActive: [
                "default.tabs.apiUsers.selfSignup.confirmation.confirmForm",
                "default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail",
                "default.tabs.apiUsers.selfSignup.resendingEmail",
            ].some(current.matches),
        },
    ]

    const stepItems = steps.map((item) => ({ key: item.title, title: item.title }))
    const currentStep = steps.findIndex((step) => step.isActive)

    return (
        <SignupFormWrapper>
            <Steps style={{ margin: `${Spaces.large} 0px` }} current={currentStep} items={stepItems} />
            <div style={{ padding: "0 5px" }}>{steps[currentStep]?.content}</div>
        </SignupFormWrapper>
    )
}

const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
`
const SignupFormWrapper = styled.div`
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: white !important;
    }
`
