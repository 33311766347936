import { useState } from "react"
import styled from "styled-components"
import { Empty, Typography, Tooltip, Button, Badge } from "antd"
import { EditOutlined, PlusCircleFilled, UserOutlined, ApartmentOutlined, ProfileOutlined } from "@ant-design/icons"

import type { User, EntityField, Config } from "../../../coreTypes/config"
import { Centered, Colors, Spaces, StyleHelpers, ItemWithFadeInAnimation } from "../../global"
import UserGroupFormWithFields, { UserFormProps } from "./UserGroupFormWithFields"
import { FieldFormProps } from "../FieldForm"

const { Title, Paragraph } = Typography

export default function UsersTabContent(props: {
    projectConfig: Config
    isObserveList: boolean
    isUserForm: boolean
    isFieldForm: boolean
    isSavingForm: boolean
    isPermissionForm: boolean
    sendUserForm: (user?: User) => void
    sendFieldForm: (field?: EntityField) => void
    userFormProps: UserFormProps
    fieldFormProps: FieldFormProps
}) {
    const {
        projectConfig,
        isObserveList,
        isUserForm,
        isFieldForm,
        isSavingForm,
        sendUserForm,
        sendFieldForm,
        userFormProps,
        fieldFormProps,
        isPermissionForm,
    } = props

    // console.log(JSON.stringify({ userForm }, null, 4))

    if (isObserveList)
        return (
            <ItemsListWrapper>
                {projectConfig.users?.length === 0 ? (
                    <Centered>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No user groups created yet" />
                    </Centered>
                ) : (
                    projectConfig.users?.map((user: User, index: number) => {
                        return <UserGroupListItem sendUserForm={sendUserForm} user={user} key={user.groupName} index={index} />
                    })
                )}
                <Centered>
                    <Button
                        icon={<PlusCircleFilled />}
                        type="primary"
                        size="large"
                        onClick={() => {
                            sendUserForm()
                        }}
                    >
                        Create user group
                    </Button>
                </Centered>
            </ItemsListWrapper>
        )

    if (isUserForm)
        return (
            <UserGroupFormWithFields
                isPermissionForm={isPermissionForm}
                projectConfig={projectConfig}
                isFieldForm={isFieldForm}
                isSavingForm={isSavingForm}
                sendFieldForm={sendFieldForm}
                fieldFormProps={fieldFormProps}
                {...userFormProps}
            />
        )

    return <>Unexpected Users tab UI state</>
}

function UserGroupListItem(props: { user: User; sendUserForm: (user?: User) => void; index: number }) {
    const { user, sendUserForm, index } = props
    const [isHovered, setIsHovered] = useState(false)

    const userConnectionsQty = user.userData.fields?.reduce((acc, field) => {
        return acc + (field.connectedEntityName ? 1 : 0)
    }, 0)

    return (
        <ListItemWrapper key={user.groupName} isHovered={isHovered} index={index}>
            <UserOutlined style={{ fontSize: "30px", color: Colors.grayDark }} />
            <UserGroupDetails>
                <Title level={3} style={{ marginTop: 0 }}>
                    {user.groupName}
                </Title>
                {/* <Tooltip
                    mouseEnterDelay={0.4}
                    mouseLeaveDelay={0}
                    placement="bottom"
                    title={user.description}
                    arrow={{ pointAtCenter: true }}
                    overlayInnerStyle={{ width: "600px" }}
                > */}
                <Paragraph ellipsis={{ rows: 2, expandable: false }}>{user.description}</Paragraph>
                {/* </Tooltip> */}
                <Badges>
                    <Tooltip
                        mouseEnterDelay={0.4}
                        mouseLeaveDelay={0}
                        title={`User has ${Object.keys(user.userData.defaultFields).length + user.userData.fields?.length} field(-s)`}
                    >
                        <BadgeItem>
                            <Badge
                                showZero
                                count={Object.keys(user.userData.defaultFields).length + user.userData.fields?.length}
                                style={{
                                    marginLeft: Spaces.small,
                                    backgroundColor: Colors.grayLight,
                                    borderColor: Colors.grayLight,
                                    color: Colors.grayDark,
                                }}
                            />
                            <ProfileOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                        </BadgeItem>
                    </Tooltip>
                    {userConnectionsQty > 0 && (
                        <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={`User has ${userConnectionsQty} connection(-s)`}>
                            <BadgeItem>
                                <Badge
                                    count={userConnectionsQty}
                                    style={{
                                        marginLeft: Spaces.small,
                                        backgroundColor: Colors.grayLight,
                                        borderColor: Colors.grayLight,
                                        color: Colors.grayDark,
                                    }}
                                />
                                <ApartmentOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                            </BadgeItem>
                        </Tooltip>
                    )}
                </Badges>
            </UserGroupDetails>
            <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onMouseEnter={() => {
                    setIsHovered(true)
                }}
                onMouseLeave={() => {
                    setIsHovered(false)
                }}
                onClick={() => {
                    setIsHovered(false)
                    sendUserForm(user)
                }}
            />
        </ListItemWrapper>
    )
}

const ItemsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: ${Spaces.large};

    & > *:first-child {
        margin-top: 16px;
    }

    width: 100%;
    max-width: 800px;
    text-align: left;
    padding: 5px ${Spaces.normal} 80px;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    scrollbar-width: none;
`

const ListItemWrapper = styled(ItemWithFadeInAnimation)<{ isHovered: boolean; index: number }>`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${Spaces.large};

    width: 100%;
    /* background-color: ${(props: { isHovered: boolean }) => (props.isHovered ? Colors.grayLight : "white")}; */
    background-color: white;
    box-shadow: ${StyleHelpers.staticBoxShadow};
    border-radius: ${StyleHelpers.radiusMedium};
    padding: ${Spaces.large};

    animation-delay: ${(props: { index: number }) =>
        props.index <= 10 ? props.index * 0.1 : 1 + props.index * 0.025}s; /* delay animation start for each item */

    button {
        order: 2;
        flex-grow: 0;
    }
`

const UserGroupDetails = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    /* Inside auto layout */
    order: 1;
    flex-grow: 1;
`

const Badges = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: ${Spaces.small} 0px 0px;
    gap: ${Spaces.large};

    /* Inside auto layout */
    order: 2;
    flex-grow: 0;
`

const BadgeItem = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: ${Spaces.small};

    /* Inside auto layout */
    order: 0;
    flex-grow: 0;
`

export { ItemsListWrapper, ListItemWrapper, Badges, BadgeItem }
